import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IGoogleHomeTraitSettings,
  updateGoogleHomeTraitSettings,
} from "../../../services/plugin/GoogleHome";
import ITrait from "../../../services/Trait";
import googleHomeTraitSettingsStore from "../../../stores/plugin/GoogleHome";

export interface IGoogleHomeTraitSettingsCard {
  trait: ITrait;
}

const GoogleHomeTraitSettingsCard: FC<IGoogleHomeTraitSettingsCard> = observer(
  (props: IGoogleHomeTraitSettingsCard) => {
    const { t } = useTranslation();
    const { trait } = props;
    const [traitSettings, setTraitSettings] =
      useState<IGoogleHomeTraitSettings>(
        googleHomeTraitSettingsStore.find(trait.uuid) || {
          trait: trait.uuid,
          protection_type: 0,
          pin: "0000",
        },
      );
    const [pin, setPIN] = useState<string>(traitSettings.pin);

    const updateProtectionMode = (protection_type: 0 | 1 | 2) => {
      setTraitSettings({
        ...traitSettings,
        protection_type: protection_type,
      });
      updateGoogleHomeTraitSettings({
        ...traitSettings,
        protection_type: protection_type,
      });
    };

    const updatePIN = (value: string) => {
      setTraitSettings({
        ...traitSettings,
        pin: value,
      });
      updateGoogleHomeTraitSettings({
        ...traitSettings,
        pin: value,
      });
    };

    return (
      <Card>
        <CardHeader title={t("Google Home")} />
        <CardContent>
          <Stack spacing={2} marginTop={-1}>
            <FormControl fullWidth>
              <InputLabel>{t("Protection mode")}</InputLabel>
              <Select
                label={t("Protection mode")}
                value={traitSettings.protection_type || 0}
                onChange={(event) =>
                  updateProtectionMode(event.target.value as 0 | 1 | 2)
                }
              >
                <MenuItem value={0}>{t("none")}</MenuItem>
                <MenuItem value={1}>{t("confirm")}</MenuItem>
                <MenuItem value={2}>{t("PIN code")}</MenuItem>
              </Select>
            </FormControl>
            {traitSettings.protection_type === 2 && (
              <TextField
                variant="outlined"
                type="text"
                label={t("PIN code")}
                value={pin}
                onChange={(event) => setPIN(event.target.value)}
                onBlur={(event) => updatePIN(event.target.value)}
              />
            )}
          </Stack>
        </CardContent>
      </Card>
    );
  },
);

export default GoogleHomeTraitSettingsCard;
