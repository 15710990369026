import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoute } from "wouter";
import AppBar from "../components/AppBar";
import DeleteFab from "../components/DeleteFab";
import Room from "../components/Room";
import SmallIconButton from "../components/SmallIconButton";
import IRoom, { deleteRoom, saveRoom } from "../services/Room";
import roomStore from "../stores/RoomStore";
import ErrorScreen from "./ErrorScreen";

const RoomScreen = observer(() => {
  const { t } = useTranslation();
  const [, params] = useRoute("/building/:building_id/room/:uuid?/");
  const building: string =
    params && params.building_id ? params.building_id : "";
  const uuid: string = params && params.uuid ? params.uuid : "";
  const [room, setRoom] = useState<IRoom>(
    roomStore.find(uuid) ||
      ({
        uuid: "",
        building_id: building,
        name: "",
      } as IRoom),
  );

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (room.name.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [room]);

  const onSave = () => {
    saveRoom(room).then(() => window.history.back());
  };

  return (
    <Box>
      <AppBar
        title={room.name || t("Add Room")}
        withBack
        right={
          isValid && (
            <SmallIconButton
              variant="contained"
              color="secondary"
              text={t("Save")}
              startIcon={<SaveIcon />}
              onClick={onSave}
            />
          )
        }
      />
      <ErrorScreen>
        <Container maxWidth="xs" sx={{ marginTop: 2 }}>
          <Card>
            <CardContent>
              <Stack spacing={2} marginBottom={-1}>
                <TextField
                  variant="outlined"
                  type="text"
                  label={t("Name")}
                  defaultValue={room.name}
                  onChange={(event) =>
                    setRoom({ ...room, name: event.target.value })
                  }
                />
              </Stack>
            </CardContent>
          </Card>
        </Container>
        <Container maxWidth="xs" sx={{ marginTop: 2 }}>
          <Room {...room} hideEditButton={true} />
        </Container>
        {uuid && (
          <DeleteFab
            confirmText={t('Do you really want to delete room "{{name}}"?', {
              name: room.name,
            })}
            onDelete={() => {
              deleteRoom(uuid).then(() => window.history.back());
            }}
          />
        )}
      </ErrorScreen>
    </Box>
  );
});

export default RoomScreen;
