import { getMessaging, getToken } from "firebase/messaging";
import getEnvironmentConfig from "../environment/config";

import app from "./app";

const messaging = getMessaging(app);

export const getMessagingToken = (): Promise<string> =>
  getToken(messaging, {
    vapidKey: getEnvironmentConfig().firebase_messaging.token,
  });

export default messaging;
