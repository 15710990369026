// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Duration } from "luxon";
import { autorun, runInAction, toJS } from "mobx";
import { logger } from "../utils";
import { IPersistedStore } from "./PersistedStore";
import userStore from "./UserStore";

// https://stackoverflow.com/a/41994659
export function typedKeys<T>(o: T): (keyof T)[] {
  // type cast should be safe because that's what really Object.keys() does
  return Object.keys(o as Record<string, unknown>) as (keyof T)[];
}

// eslint-disable-next-line max-len
export const clearStores = (
  exlude: string[] = [],
): Promise<PromiseSettledResult<false | void>[]> => {
  const storagesToClear = Object.keys(window.localStorage)
    .filter((store) => exlude.indexOf(store) === -1)
    .map(
      (store) =>
        store.startsWith("raspautomation.") &&
        !store.startsWith("raspautomation.UI") &&
        window.localStorage.removeItem(store),
    );
  return Promise.allSettled(storagesToClear);
};

export const persist = (
  source: IPersistedStore,
  endpoint: string,
  properties: string[] = ["instances", "hiddenInstances"],
  callback?: () => void,
  delay = 30000,
): void => {
  if (!endpoint) {
    throw Error("Store endpoint is not set, cannot persist!");
  }
  logger(`Persisting raspautomation.${endpoint}!`);
  const storeKey = `raspautomation.${endpoint}`;
  const store = JSON.parse(localStorage.getItem(storeKey) || "{}");
  properties.forEach((key) => {
    const storedData = store[key];
    if (storedData !== undefined) {
      runInAction(() => {
        source[key as keyof IPersistedStore] = storedData;
      });
    }
  });
  runInAction(() => {
    source.isHydrated = true;
  });
  if (callback) {
    callback();
  }

  autorun(
    () => {
      if (!source.isHydrated) {
        logger(
          `Not storing "raspautomation.${endpoint}" as it's not hydrated yet!`,
        );
      }
      if (userStore && !userStore.token) {
        logger(
          `Not storing "raspautomation.${endpoint}" as user has logged out!`,
        );
        return;
      }
      logger(`Storing "raspautomation.${endpoint}"!`);
      localStorage.setItem(
        storeKey,
        JSON.stringify(
          Object.fromEntries(
            properties.map((key) => [
              key,
              toJS(source[key as keyof IPersistedStore]),
            ]),
          ),
        ),
      );
    },
    {
      delay: delay,
    },
  );
};

export const formatDuration = (milliseconds: number): string => {
  return (
    Duration.fromObject({
      minutes: Math.ceil(milliseconds / 60000),
    })
      .shiftTo("hours", "minutes")
      .rescale()
      .toHuman({ unitDisplay: "short" }) ||
    Duration.fromObject({ minutes: 0 }).toHuman({ unitDisplay: "short" })
  );
};
