import { useMediaQuery, useTheme } from "@mui/material";
import { toJS } from "mobx";
import { useEffect, useRef } from "react";

export const isDevelopment = process.env.NODE_ENV === "development";

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const logger = (...messages: any): void => {
  if (!isDevelopment) {
    return;
  }
  // eslint-disable-next-line no-console, @typescript-eslint/no-explicit-any
  console.log(...messages.map((message: any) => toJS(message)));
};

export const isSmall = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
};
