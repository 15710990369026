import { DropResult } from "@hello-pangea/dnd";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import IGroup from "../services/Group";
import groupStore from "../stores/GroupStore";
import traitStore from "../stores/TraitStore";
import groupUIStore from "../stores/UI/GroupUIStore";
import TraitCard, { ITraitGroup } from "./TraitCard";
import { ITraitChipHide } from "./TraitChip";

export interface IGroupComponent {
  uuid_or_group: string | IGroup;
  hideEditButton?: boolean;
}

const Group: FC<IGroupComponent> = observer((props: IGroupComponent) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { uuid_or_group, hideEditButton } = props;
  const group =
    typeof uuid_or_group === "string"
      ? groupStore.find(uuid_or_group)
      : uuid_or_group;

  if (!group) {
    return null;
  }

  const { hiddenTraits, traitOrder } = groupUIStore.find(group.uuid);
  const traitGroups = group.traitgroups
    .slice()
    .sort((a, b) =>
      traitOrder.indexOf(a.uuid) < traitOrder.indexOf(b.uuid) ? -1 : 1,
    )
    .map(
      (traitGroup) =>
        ({
          id: traitGroup.uuid,
          name: traitGroup.name,
          traits: traitGroup.traits.map((uuid) => traitStore.find(uuid)),
        }) as ITraitGroup,
    );
  const onHide = (instance: ITraitChipHide) =>
    groupUIStore.hideTrait(group.uuid, instance.id as string);
  const onShow = (instance: ITraitChipHide) =>
    groupUIStore.showTrait(group.uuid, instance.id as string);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = [...traitGroups.map((traitGroup) => traitGroup.id)];
    const [moved] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, moved);
    groupUIStore.setTraitOrder(group.uuid, newOrder);
  };

  return (
    <TraitCard
      id={
        hideEditButton ? `editable-group-${group.uuid}` : `group-${group.uuid}`
      }
      title={group.name}
      traitGroups={traitGroups}
      hiddenTraits={hiddenTraits}
      onHide={onHide}
      onShow={onShow}
      onDragEnd={onDragEnd}
      actions={
        hideEditButton
          ? undefined
          : [
              <Button
                key={`edit-${group.uuid}`}
                variant="contained"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={() =>
                  setLocation(
                    `/building/${group.building_id}/group/${group.uuid}/`,
                  )
                }
              >
                {t("Edit")}
              </Button>,
            ]
      }
    />
  );
});

export default Group;
