import { Container, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { translateTrait } from "../components/TranslatedTrait";

const traitClasses = [
  "Temperature",
  "Humidity",
  "BatteryLevel",
  "Voltage",
  "Current",
  "Power",
  "Energy",
  "Frequency",
  "Conductivity",
  "Illuminance",
  "SoilMoisture",
  "PressureBar",
  "PressurehPa",
  "LinkQuality",
  "Occupancy",
  "VOC",
  "CO2",
  "Formaldehyd",
  "PM25",
  "WindSpeed",
  "WindDirection",
  "CloudCoverage",
  "TransferRate",
  "RemainingTime",
  "WaterLeak",
  "Distance",
  "SpeedKmPerH",
  "OnOff",
  "PowerOnBehavior",
  "Dimmer",
  "LightTemperature",
  "Blind",
  "Action",
  "Contact",
  "FanSpeed",
  "ACMode",
  "Swing",
  "ColorXY",
  "RadarSensitivity",
  "Range",
  "Delay",
  "Lock",
  "ToshibaPowerSelection",
  "ToshibaMeritA",
  "SHeating",
  "SOperatingMode",
  "ChargingState",
  "UnifiPoEPowerCycle",
  "ImageCaptureInterval",
  "CameraImage",
];

const TranslationTestScreen = observer(() => {
  return (
    <Container sx={{ marginTop: 4 }}>
      <LanguageSwitcher />
      {traitClasses
        .map((cls) => translateTrait(cls) || `   MISSING ${cls}`)
        .sort((a, b) => (a < b ? -1 : 1))
        .map((cls) => (
          <Typography key={cls}>{cls}</Typography>
        ))}
    </Container>
  );
});

export default TranslationTestScreen;
