import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Chip, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import ITrait, { calculateTraitDisplayValue } from "../services/Trait";
import traitStore from "../stores/TraitStore";
import TraitAvatar from "./TraitAvatar";
import TraitPopper from "./TraitPopper";
import TraitValueText from "./TraitValue";
import TranslatedTrait from "./TranslatedTrait";

export interface ITraitChipHide {
  traitClass?: string;
  id?: string;
  uuid?: string | string[];
}

export interface ITraitChip {
  id: string;
  traits: ITrait[];
  name?: string;
  hidden?: boolean;
  full?: boolean;
  onHide?: (instance: ITraitChipHide) => void;
  onShow?: (instance: ITraitChipHide) => void;
}

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
  clickable: {
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
  },
});

const TraitChip: FC<ITraitChip> = observer((props: ITraitChip) => {
  const styles = useStyles();
  const { id, name, hidden, full, onShow, onHide } = props;
  const traits = props.traits.filter((trait) => trait !== undefined);
  const unit = traits.length > 0 ? traits[0].unit : "";
  const controllableTraits = traits.filter((trait) => trait.controllable);
  const traitClass: string = traits.length > 0 ? traits[0].trait_class : "";
  const value: number | string | null = calculateTraitDisplayValue(
    traitClass,
    traits,
  );
  const controllableValue: number | string | null = calculateTraitDisplayValue(
    traitClass,
    controllableTraits,
  );
  const controllable: boolean = controllableTraits.length > 0;

  const [modalOpen, setModalOpen] = useState<string | null>(null);
  const [popperAnchor, setPopperAnchor] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [open, setOpen] = useState<boolean>(false);
  const popperId = open ? `popper-${traitClass}` : undefined;

  if (
    (hidden && !full) ||
    traitClass === "Action" ||
    (["UnifiPoEPowerCycle"].indexOf(traitClass) > -1 &&
      controllableTraits.length === 0)
  ) {
    return <></>;
  }

  if (traitClass === "CameraImage") {
    return (
      <>
        {traits.map((camera) => (
          <Box
            key={open ? `open-camera-${camera.uuid}` : `camera-${camera.uuid}`}
          >
            <Box
              component="img"
              width="100%"
              src={`data:image/jpeg;base64, ${camera.value}`}
              onClick={() => setModalOpen(camera.uuid)}
            />
            <Modal
              open={modalOpen === camera.uuid}
              onClose={() => setModalOpen(null)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                maxWidth="100%"
                maxHeight="100%"
                onClick={() => setModalOpen(null)}
                src={`data:image/jpeg;base64, ${camera.value}`}
              />
            </Modal>
          </Box>
        ))}
      </>
    );
  }

  return (
    <>
      {controllable && (
        <TraitPopper
          id={popperId}
          name={name}
          traitClass={traitClass}
          value={controllableValue}
          unit={unit}
          anchor={popperAnchor}
          open={open}
          setOpen={setOpen}
          onSet={(value: number | string) =>
            controllableTraits.forEach((trait) =>
              traitStore.set(trait.uuid, value),
            )
          }
        />
      )}
      <Chip
        className={`${controllable && styles.clickable} ${
          full && styles.fullWidth
        }`}
        clickable={controllable}
        onClick={(event) => {
          setPopperAnchor(event.currentTarget);
          setOpen(true);
        }}
        avatar={
          <TraitAvatar traitClass={traitClass} value={value} unit={unit} />
        }
        label={
          full ? (
            <span>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              {name || <TranslatedTrait traitClass={traitClass} />}:&nbsp;
              <TraitValueText
                traitClass={traitClass}
                value={value}
                unit={unit}
              />
            </span>
          ) : (
            <TraitValueText traitClass={traitClass} value={value} unit={unit} />
          )
        }
        deleteIcon={
          hidden && onShow && onHide ? (
            <VisibilityOffIcon />
          ) : (
            <VisibilityIcon />
          )
        }
        onDelete={
          full && onShow && onHide
            ? () =>
                hidden
                  ? onShow &&
                    onShow({
                      id: id,
                      traitClass: traitClass,
                      uuid: traits.length === 1 ? traits[0].uuid : undefined,
                    })
                  : onHide &&
                    onHide({
                      id: id,
                      traitClass: traitClass,
                      uuid: traits.length === 1 ? traits[0].uuid : undefined,
                    })
            : undefined
        }
        variant="filled"
      />
    </>
  );
});

export default TraitChip;
