import { action, makeObservable, observable, runInAction } from "mobx";
import { IPersistedStore } from "../PersistedStore";
import { persist } from "../utils";

export interface IBuildingPageUIStore extends IPersistedStore {
  selectedTab: number;
  setSelectedTab: (value: number) => void;
}

class BuildingPageUIStore implements IBuildingPageUIStore {
  isHydrated = false;
  selectedTab = 0;

  constructor() {
    makeObservable(this, {
      isHydrated: observable,
      selectedTab: observable,
      setSelectedTab: action,
    });

    persist(this, "UI.building", ["selectedTab"], undefined, 1);
  }

  setSelectedTab = (value: number) => {
    runInAction(() => {
      this.selectedTab = value;
    });
  };
}

const buildingPageUIStore = new BuildingPageUIStore();
export default buildingPageUIStore;
