/* eslint-disable react/destructuring-assignment */
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import traitStore from "../stores/TraitStore";
import TranslatedTrait from "./TranslatedTrait";

export interface ITraitGroupEdit {
  isNew: boolean;
  uuid: string;
  building: string;
  traitClass?: string;
  name: string;
  group_as_one: boolean;
  traits: string[];
  onChange?: (value: ITraitGroupEdit) => void;
  onDelete?: (isNew: boolean, uuid: string) => void;
}

const TraitGroupEdit: FC<ITraitGroupEdit> = (props: ITraitGroupEdit) => {
  const { t } = useTranslation();
  const traits = traitStore.filter({
    building_id: props.building,
    sorted: true,
  });
  const traitClasses = traitStore
    .getSortedTraitClasses(traits)
    .filter((trait_class, index, self) => self.indexOf(trait_class) === index);
  const [name, setName] = useState(props.name);
  const [committedName, setCommittedName] = useState(props.name);
  const [selectedTraits, setSelectedTraits] = useState<string[]>(props.traits);
  const [committedSelectedTraits, setCommittedSelectedTraits] = useState<
    string[]
  >(props.traits);
  const [traitClass, setTraitClass] = useState(
    props.traitClass || traitClasses[0],
  );

  useEffect(() => {
    if (props.onChange) {
      props.onChange({
        isNew: props.isNew,
        uuid: props.uuid,
        building: props.uuid,
        traitClass: traitClass,
        name: committedName,
        group_as_one: true,
        traits: committedSelectedTraits,
      });
    }
  }, [committedName, committedSelectedTraits]);

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <TextField
            variant="outlined"
            type="text"
            label={t("Name")}
            value={name}
            required
            onChange={(event) => setName(event.target.value)}
            onBlur={(event) => setCommittedName(event.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>{t("Class")}</InputLabel>
            <Select
              variant="outlined"
              label={t("Class")}
              value={traitClass}
              renderValue={(value) => <TranslatedTrait traitClass={value} />}
              onChange={(event) => {
                const { value } = event.target;
                if (value !== traitClass) {
                  setSelectedTraits([]);
                  setCommittedSelectedTraits([]);
                }
                setTraitClass(value);
              }}
            >
              {traitClasses.map((traitclass) => (
                <MenuItem key={traitclass} value={traitclass}>
                  <TranslatedTrait traitClass={traitclass} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>{t("Devices")}</InputLabel>
            <Select
              label={t("Devices")}
              multiple
              value={selectedTraits}
              renderValue={(selected) =>
                selected.map((item) => traitStore.getTraitText(item)).join(", ")
              }
              onChange={(event) => {
                const { value } = event.target;
                setSelectedTraits(
                  typeof value === "string"
                    ? (value as string).split(",")
                    : value,
                );
              }}
              onClose={() => {
                setCommittedSelectedTraits(selectedTraits);
              }}
            >
              {traits
                .filter((trait) => trait.trait_class === traitClass)
                .map((trait) => (
                  <MenuItem key={trait.uuid} value={trait.uuid}>
                    <Checkbox
                      color="secondary"
                      checked={selectedTraits.indexOf(trait.uuid) > -1}
                    />
                    {traitStore.getTraitText(trait.uuid)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "flex-start",
        }}
      >
        <Button
          color="error"
          variant="contained"
          onClick={() =>
            props.onDelete && props.onDelete(props.isNew, props.uuid)
          }
        >
          <DeleteForeverIcon />
          {t("Delete")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default TraitGroupEdit;
