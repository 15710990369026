import { Alert } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import coreStore from "../stores/CoreStore";

const UpdateNotification = observer(() => {
  const { t } = useTranslation();
  const { updateAvailable } = coreStore;
  if (!updateAvailable) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        cursor: "pointer",
      }}
      onClick={() => {
        window.navigator.serviceWorker
          .getRegistration()
          .then((registration) => {
            registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
          });
      }}
    >
      {t("Update available! Click to update.")}
    </Alert>
  );
});

export default UpdateNotification;
