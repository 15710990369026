import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import buildingStore from "../../../stores/BuildingStore";
import firebaseMessagingStore from "../../../stores/plugin/FirebaseMessaging";

export interface IFirebaseNotificationsCard {
  buildings?: string[];
}

const FirebaseNotificationsCard = observer(
  (props: IFirebaseNotificationsCard) => {
    const { t } = useTranslation();
    const { subscriptions, addSubscription, removeSubscription } =
      firebaseMessagingStore;
    const buildings = subscriptions.map(
      (subscription) => subscription.building,
    );

    if (!firebaseMessagingStore.plugin_enabled) {
      return null;
    }

    return (
      <Card>
        <CardHeader title={t("Notifications")} />
        <CardContent>
          <Stack spacing={2} marginTop={-1} marginLeft={2}>
            {buildingStore.instances
              .slice()
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .filter(
                (building) =>
                  !props.buildings ||
                  props.buildings.indexOf(building.uuid) > -1,
              )
              .map((building) => (
                <FormGroup key={building.uuid}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={buildings.indexOf(building.uuid) > -1}
                        onChange={(_, checked) => {
                          if (checked) {
                            addSubscription(building.uuid);
                          } else {
                            removeSubscription(building.uuid);
                          }
                        }}
                      />
                    }
                    label={building.name}
                  />
                </FormGroup>
              ))}
          </Stack>
        </CardContent>
      </Card>
    );
  },
);

export default FirebaseNotificationsCard;
