import { DateTime } from "luxon";
import { makeObservable, observable, runInAction } from "mobx";
import { fetchPrices, IPricePoint } from "../../../../services/plugin/Nordpool";
import { IPersistedStore } from "../../../PersistedStore";
import { persist } from "../../../utils";

export type TPriceSelection = "spot" | "energy" | "total";
export type TEnergyPrices = { [key: string]: IPricePoint[] };

export interface IEnergyPriceSettings {
  showEnergyPricesOnTop: boolean;
  priceSelection?: TPriceSelection;
  usePriceRange?: boolean;
  priceRange?: [number, number];
}

export interface INordpoolUIStore extends IPersistedStore {
  prices: TEnergyPrices;
  energyPriceSettings: IEnergyPriceSettings;
  setPriceSelection: (value: TPriceSelection) => void;
  toggleUsePriceRange: () => void;
  setPriceRange: (min: number, max: number) => void;
  toggleShowEnergyPricesOnTop: () => void;
  getUpcomingPrices: (
    building: string,
    forceUpdate: boolean,
  ) => Promise<IPricePoint[]>;
}

class NordpoolUIStore implements INordpoolUIStore {
  prices: TEnergyPrices = {};
  isHydrated = false;
  energyPriceSettings: IEnergyPriceSettings = {
    showEnergyPricesOnTop: false,
    priceSelection: "spot",
    usePriceRange: true,
    priceRange: [2, 10],
  };

  constructor() {
    makeObservable(this, {
      isHydrated: observable,
      energyPriceSettings: observable,
      prices: observable,
    });

    persist(
      this,
      "UI.nordpool",
      ["energyPriceSettings", "prices"],
      undefined,
      1,
    );
  }

  setPriceSelection = (value: TPriceSelection) => {
    runInAction(() => {
      this.energyPriceSettings = {
        ...this.energyPriceSettings,
        priceSelection: value,
      };
    });
  };

  toggleUsePriceRange = () => {
    runInAction(() => {
      this.energyPriceSettings = {
        ...this.energyPriceSettings,
        usePriceRange: !this.energyPriceSettings.usePriceRange,
      };
    });
  };

  setPriceRange = (min: number, max: number) => {
    runInAction(() => {
      this.energyPriceSettings = {
        ...this.energyPriceSettings,
        priceRange: [min, max],
      };
    });
  };

  toggleShowEnergyPricesOnTop = () => {
    runInAction(() => {
      this.energyPriceSettings = {
        ...this.energyPriceSettings,
        showEnergyPricesOnTop: !this.energyPriceSettings.showEnergyPricesOnTop,
      };
    });
  };

  getUpcomingPrices = async (
    building: string,
    forceUpdate: boolean = false,
  ): Promise<IPricePoint[]> => {
    const startOfHour = DateTime.now().startOf("hour");
    const existingPrices = (this.prices[building] || []).filter(
      (x) => DateTime.fromISO(x.time).valueOf() >= startOfHour.valueOf(),
    );
    if (!forceUpdate && existingPrices && existingPrices.length >= 10) {
      return existingPrices;
    }

    const response = await fetchPrices({
      building_id: building,
      start_time: startOfHour.toISO(),
      end_time: startOfHour.plus({ hours: 24 }).toISO(),
      unit: "c/kWh",
    });
    runInAction(() => {
      this.prices[building] = response.points;
    });
    return response.points;
  };
}

const nordpoolUiStore = new NordpoolUIStore();
export default nordpoolUiStore;
