import { SvgIcon } from "@mui/material";
import React from "react";

const BlindIcon = (): React.ReactElement => (
  <SvgIcon>
    <path d="M 22.399992,-1.5128596e-4 H 1.5999733 C 0.71630757,-1.5128596e-4 0,0.71634017 0,1.5998237 0,2.4834905 0.71630757,3.1999819 1.5999733,3.1999819 V 22.799952 c 7.325e-4,0.662476 0.5375969,1.199156 1.2000715,1.199889 H 20.399935 c 0.662475,-7.32e-4 1.199339,-0.537413 1.200071,-1.199889 V 3.1999819 h 0.799986 v 0.3999023 c 0,0.2210082 0.179078,0.4000853 0.399902,0.4000853 0.221009,0 0.400085,-0.1790771 0.400085,-0.4000853 V 2.9773257 C 23.827114,2.6167912 24.1329,1.8794258 23.945216,1.1808785 23.757534,0.48233148 23.123258,-0.00234855 22.399992,-1.5128596e-4 Z M 0.79998665,1.5998237 c 0,-0.4418332 0.35815375,-0.79998746 0.79998665,-0.79998746 0.4418329,0 0.7999867,0.35815426 0.7999867,0.79998746 0,0.4418334 -0.3581538,0.8001707 -0.7999867,0.8001707 C 1.1583235,2.3994451 0.80035287,2.041474 0.79998665,1.5998237 Z M 20,21.4 c 0,1 0,1.2 -1,1.2 H 4 C 3,22.6 3,22.4 3,21.4 V 3.6 H 20 Z M 22.3,2 H 2.9774738 C 3.1215776,1.7577516 3.098352,1.7817993 3.1,1.5 3.098352,1.2183839 3.1215776,1.2420654 2.9774738,1 H 22.3 C 22.741833,1 23,1.058167 23,1.5 23,1.9418334 22.741833,2 22.3,2 Z" />
    <path d="m 22.799894,7.1999194 c 0.221009,0 0.400085,-0.1790768 0.400085,-0.400085 V 5.1998591 c 0,-0.2208249 -0.179076,-0.3999024 -0.400085,-0.3999024 -0.220824,0 -0.399902,0.1790775 -0.399902,0.3999024 v 1.5999753 c 0,0.2210082 0.179078,0.400085 0.399902,0.400085 z m 0,0" />
    <path d="m 22.399992,9.9999677 c 0,0.2208253 0.179078,0.3999023 0.399902,0.3999023 0.221009,0 0.400085,-0.179077 0.400085,-0.3999023 V 8.3999922 c 0,-0.221008 -0.179076,-0.400085 -0.400085,-0.400085 -0.220824,0 -0.399902,0.179077 -0.399902,0.400085 z m 0,0" />
    <path d="m 22.399992,13.199917 c 0,0.220826 0.179078,0.399902 0.399902,0.399902 0.221009,0 0.400085,-0.179076 0.400085,-0.399902 v -1.599974 c 0,-0.221009 -0.179076,-0.400086 -0.400085,-0.400086 -0.220824,0 -0.399902,0.179077 -0.399902,0.400086 z m 0,0" />
    <path d="m 22.399992,16.399867 c 0,0.221009 0.179078,0.400085 0.399902,0.400085 0.221009,0 0.400085,-0.179076 0.400085,-0.400085 v -1.599974 c 0,-0.220826 -0.179076,-0.399903 -0.400085,-0.399903 -0.220824,0 -0.399902,0.179077 -0.399902,0.399903 z m 0,0" />
    <path d="m 22.399992,19.599818 c 0,0.221009 0.179078,0.400085 0.399902,0.400085 0.221009,0 0.400085,-0.179076 0.400085,-0.400085 v -1.599974 c 0,-0.220826 -0.179076,-0.399903 -0.400085,-0.399903 -0.220824,0 -0.399902,0.179077 -0.399902,0.399903 z m 0,0" />
  </SvgIcon>
);

export default BlindIcon;
