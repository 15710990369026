import IDevice from "../services/Device";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";
import roomStore from "./RoomStore";
import deviceUIStore from "./UI/DeviceUIStore";

export interface IDeviceFilter extends IBuildingChildFilter {
  room_id?: string;
  gateway_id?: string;
  disabled?: boolean;
}

class DeviceStore extends BuildingChildPagedStore<IDevice, IDeviceFilter> {
  constructor() {
    super("device");
    this.persist();
  }

  sort = (a: IDevice, b: IDevice): 1 | -1 => {
    return deviceUIStore.findIndex(a.uuid) < deviceUIStore.findIndex(b.uuid)
      ? -1
      : 1;
  };
}

const deviceStore = new DeviceStore();

export const getDeviceText = (uuid: string): string => {
  const device = deviceStore.find(uuid);
  if (!device) {
    return "";
  }
  const room = roomStore.find(device?.room_id || "");
  return `${room ? `(${room.name})` : ""} ${device.name}`.trim();
};

export default deviceStore;
