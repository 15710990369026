import { action, makeObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { translateTrait } from "../components/TranslatedTrait";
import ITrait, { setTraitValue } from "../services/Trait";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";
import deviceStore from "./DeviceStore";
import roomStore from "./RoomStore";

export interface ITraitFilter extends IBuildingChildFilter {
  room_id?: string | null;
  gateway_id?: string;
  device_id?: string;
  trait_class?: string;
  channel?: string | null;
  disabled?: boolean;
  controllable?: boolean;
}

export type TSimpleTrait = Pick<
  ITrait,
  "trait_class" | "uuid" | "name" | "unit"
>;

class TraitStore extends BuildingChildPagedStore<ITrait, ITraitFilter> {
  constructor() {
    super("trait");
    this.persist();

    makeObservable(this, {
      set: action,
    });
  }

  set = async (uuid: string, value: number | string) => {
    const instance = this.find(uuid);
    if (!instance) {
      return;
    }
    setTraitValue(uuid, value);
  };

  sort = (a: ITrait, b: ITrait): 1 | -1 => {
    return this.getTraitText(a) < this.getTraitText(b) ? -1 : 1;
  };

  getTraitText = computedFn((trait_or_uuid: ITrait | string) => {
    let trait: ITrait | string | undefined = trait_or_uuid;
    if (typeof trait_or_uuid === "string") {
      trait = this.find(trait_or_uuid);
    }
    if (!trait) {
      return "";
    }
    if (typeof trait === "string") {
      return "";
    }
    if (trait.nickname) {
      return trait.nickname.trim();
    }
    const device = deviceStore.find(trait.device_id);
    const room = roomStore.find(device?.room_id || "");
    return `${room ? `(${room.name})` : ""} ${device?.name} ${
      trait.channel ? `(${trait.channel})` : ""
    }`.trim();
  });

  getSortedTraitClasses = (traits?: TSimpleTrait[]) => {
    return (traits === undefined ? this.instances : traits)
      .map((trait) => trait.trait_class)
      .filter((trait_class, index, self) => self.indexOf(trait_class) === index)
      .sort((a, b) => (translateTrait(a) < translateTrait(b) ? -1 : 1));
  };
}

const traitStore = new TraitStore();

export default traitStore;
