import IBuilding from "../services/Building";
import { logger } from "../utils";
import coreStore from "./CoreStore";
import deviceStore from "./DeviceStore";
import gatewayStore from "./GatewayStore";
import groupStore from "./GroupStore";
import PagedStore, { IPagedStoreFilter } from "./PagedStore";
import googleHomeTraitSettingsStore from "./plugin/GoogleHome";
import electricityContractStore from "./plugin/Nordpool";
import roomStore from "./RoomStore";
import ruleStore from "./RuleStore";
import stateStore from "./StateStore";
import traitStore from "./TraitStore";

class BuildingStore extends PagedStore<IBuilding, IPagedStoreFilter> {
  constructor() {
    super("building");
    this.persist();
  }

  sort = (a: IBuilding, b: IBuilding): 1 | -1 => {
    return a.name < b.name ? -1 : 1;
  };
}
const buildingStore = new BuildingStore();

export const refreshBuilding = async (
  building_uuid: string,
): Promise<unknown> => {
  logger("Refreshing building");
  coreStore.updateLastRefresh(building_uuid);
  const tasks = [
    buildingStore.fetch(building_uuid),
    roomStore.fetchFiltered({ building_id: building_uuid }),
    gatewayStore.fetchFiltered({ building_id: building_uuid }),
    groupStore.fetchFiltered({ building_id: building_uuid }),
    deviceStore.fetchFiltered({ building_id: building_uuid }),
    traitStore.fetchFiltered({ building_id: building_uuid }),
    ruleStore.fetchFiltered({ building_id: building_uuid }),
    stateStore.fetchFiltered({ building_id: building_uuid }),
  ];

  await coreStore.update();
  if (coreStore.isPluginEnabled("googlehome")) {
    tasks.push(googleHomeTraitSettingsStore.fetchAll());
  }
  if (coreStore.isPluginEnabled("nordpool")) {
    tasks.push(electricityContractStore.fetchAll());
  }
  return Promise.allSettled(tasks);
};

export default buildingStore;
