import { onMessage } from "firebase/messaging";
import { autorun, makeObservable, observable, runInAction } from "mobx";
import { VariantType } from "notistack";
import SnackbarUtils from "../../components/SnackbarUtils";
import messaging, { getMessagingToken } from "../../firebase/messaging";
import {
  IFirebaseMessagingSubscription,
  subscribeToBuilding,
  unsubscribeFromBuilding,
  updateFirebaseMessagingSubscriptions,
} from "../../services/plugin/FirebaseMessaging";
import coreStore from "../CoreStore";
import { IPersistedStore } from "../PersistedStore";
import userStore from "../UserStore";
import { persist } from "../utils";

export interface IFirebaseMessagingStore extends IPersistedStore {
  plugin_enabled: boolean;
  token: string | null;
  subscriptions: IFirebaseMessagingSubscription[];
  permission: NotificationPermission;
  requestPermission: () => void;
  updateToken: () => void;
  updateSubscriptions: () => void;
  addSubscription: (uuid: string) => void;
  removeSubscription: (uuid: string) => void;
  removeAllSubscriptions: () => void;
}

class FirebaseMessagingStore implements IFirebaseMessagingStore {
  isHydrated = false;
  plugin_enabled = false;
  token: string | null = null;
  subscriptions: IFirebaseMessagingSubscription[] = [];
  permission: NotificationPermission = "default";

  constructor() {
    makeObservable(this, {
      plugin_enabled: observable,
      token: observable,
      subscriptions: observable,
      permission: observable,
      isHydrated: observable,
    });

    persist(
      this,
      "plugin.firebasemessaging",
      ["token", "subscriptions"],
      undefined,
      1,
    );

    autorun(() => {
      runInAction(() => {
        this.plugin_enabled = coreStore.isPluginEnabled("firebase_messaging");
        this.permission = Notification.permission;
      });
    });

    autorun(() => {
      if (this.isHydrated && this.plugin_enabled) {
        if (this.permission === "default") {
          this.requestPermission();
        } else if (this.permission === "granted") {
          this.updateToken();
        }
        userStore.addLogoutCallback(this.removeAllSubscriptions);
      }
    });

    autorun(() => {
      if (userStore.token && this.token && this.plugin_enabled) {
        this.updateSubscriptions();
      }
    });

    onMessage(messaging, (payload) => {
      if (payload.data?.title && payload.data?.body) {
        SnackbarUtils.toast(
          `${payload.data.title}\n${payload.data.body}`,
          (payload.data?.variant || "success") as VariantType,
        );
      }
    });
  }

  requestPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        runInAction(() => {
          this.permission = permission;
        });
      } else {
        runInAction(() => {
          this.permission = permission;
        });
      }
    });
  };

  updateToken = () => {
    console.log("Firebase: Updating token!");
    getMessagingToken().then((token) => {
      if (token) {
        runInAction(() => {
          this.token = token;
        });
      } else {
        runInAction(() => {
          this.token = null;
        });
      }
    });
  };

  updateSubscriptions = () => {
    console.log("Firebase: Updating buildings list for token.");
    if (!this.token) {
      return;
    }

    updateFirebaseMessagingSubscriptions(this.token).then((subscriptions) => {
      runInAction(() => {
        this.subscriptions = subscriptions;
      });
    });
  };

  addSubscription = (uuid: string) => {
    console.log(`Firebase: Adding subscription for Building ${uuid}`);
    if (
      !this.token ||
      this.subscriptions.find((subscription) => subscription.building === uuid)
    ) {
      return;
    }

    subscribeToBuilding(this.token as string, uuid).then((subscription) => {
      runInAction(() => {
        this.subscriptions.push(subscription);
      });
    });
  };

  removeSubscription = async (uuid: string): Promise<void> => {
    console.log(`Firebase: Removing Building ${uuid}`);
    const subscription = this.subscriptions.find(
      (subscription) =>
        subscription.building === uuid && subscription.fcm_token === this.token,
    );
    if (!this.token || !subscription) {
      return;
    }

    return unsubscribeFromBuilding(subscription.id).then(() => {
      runInAction(() => {
        this.subscriptions = this.subscriptions.filter(
          (x) => x.id !== subscription.id,
        );
      });
    });
  };

  removeAllSubscriptions = async (): Promise<void[]> => {
    const promises = this.subscriptions.map((subscription) =>
      this.removeSubscription(subscription.building),
    );
    return Promise.all(promises);
  };
}

const firebaseMessagingStore = new FirebaseMessagingStore();
export default firebaseMessagingStore;
