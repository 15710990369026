import IGroup from "../services/Group";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";
import groupUIStore from "./UI/GroupUIStore";

export type IGroupFilter = IBuildingChildFilter;

class GroupStore extends BuildingChildPagedStore<IGroup, IGroupFilter> {
  constructor() {
    super("group");
    this.persist();
  }

  sort = (a: IGroup, b: IGroup): 1 | -1 => {
    return groupUIStore.findIndex(a.uuid) < groupUIStore.findIndex(b.uuid)
      ? -1
      : 1;
  };
}

const groupStore = new GroupStore();
export default groupStore;
