import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, ButtonGroup } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { IChartTimes } from "./ChartList";

export interface IChartButtonGroup {
  times: IChartTimes;
  onSetChartTimes: (times: IChartTimes) => void;
}

const ChartButtonGroup: FC<IChartButtonGroup> = observer(
  (props: IChartButtonGroup) => {
    const { times, onSetChartTimes } = props;
    const { startTime, endTime } = times;

    return (
      <ButtonGroup variant="contained" sx={{ width: "100%" }}>
        <Button
          onClick={() => {
            onSetChartTimes({
              startTime: startTime.minus({ hours: 24 }),
              endTime: endTime.minus({ hours: 24 }),
            });
          }}
        >
          <ArrowBackIcon />
        </Button>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            value={startTime}
            onChange={(time) => {
              if (!time) {
                return;
              }
              onSetChartTimes({
                startTime: time,
                endTime: time.endOf("day"),
              });
            }}
            // // eslint-disable-next-line react/jsx-props-no-spreading
            // renderInput={(params) => (
            //   <TextField {...params} sx={{ flexGrow: 2 }} />
            // )}
            maxDate={DateTime.now().startOf("day")}
          />
        </LocalizationProvider>
        <Button
          onClick={() => {
            onSetChartTimes({
              startTime: startTime.plus({ hours: 24 }),
              endTime: endTime.plus({ hours: 24 }),
            });
          }}
          disabled={
            startTime.plus({ hours: 24 }).diffNow().as("milliseconds") > 0
          }
        >
          <ArrowForwardIcon />
        </Button>
      </ButtonGroup>
    );
  },
);

export default ChartButtonGroup;
