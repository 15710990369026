import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { observer } from "mobx-react-lite";
import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { Route, useLocation } from "wouter";
import { SnackbarUtilsConfigurator } from "./components/SnackbarUtils";
import UpdateNotification from "./components/UpdateNotification";
import BuildingListScreen from "./screens/BuildingListScreen";
import BuildingScreen from "./screens/BuildingScreen";
import BuildingSettingsScreen from "./screens/BuildingSettingsScreen";
import ChartEditScreen from "./screens/ChartEditScreen";
import DeviceScreen from "./screens/DeviceScreen";
import GatewayScreen from "./screens/GatewayScreen";
import GroupScreen from "./screens/GroupScreen";
import LoginScreen from "./screens/LoginScreen";
import LogoutScreen from "./screens/LogoutScreen";
import RoomScreen from "./screens/RoomScreen";
import RuleScreen from "./screens/RuleScreen";
import SettingsScreen from "./screens/SettingsScreen";
import StateScreen from "./screens/StateScreen";
import TranslationTestScreen from "./screens/TranslationTestScreen";
import ElectricityContractScreen from "./screens/plugin/nordpool/ElectricityContractScreen";
import coreStore from "./stores/CoreStore";
import mqttStore from "./stores/MQTTStore";
import userStore from "./stores/UserStore";
import firebaseMessagingStore from "./stores/plugin/FirebaseMessaging";
import mainTheme from "./themes/main";
import { logger } from "./utils";

const App = observer(() => {
  const [location, setLocation] = useLocation();
  const [building, setBuilding] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location]);

  useEffect(() => {
    if (!coreStore.isHydrated || !userStore.isHydrated) {
      return;
    }
    const loggedIn = !!userStore.token;
    const isRoot: boolean = ["", "/"].indexOf(location) !== -1;

    if (isRoot) {
      if (loggedIn) {
        if (coreStore.previousBuilding !== null) {
          setBuilding(coreStore.previousBuilding);
          setLocation(`/building/${coreStore.previousBuilding}/`, {
            replace: true,
          });
        } else {
          setLocation("/building/", { replace: true });
        }
      } else {
        setLocation("/login/", { replace: true });
        setBuilding(null);
      }
    } else if (!loggedIn && !["/login/", "/settings/"].includes(location)) {
      setLocation("/login/", { replace: true });
      setBuilding(null);
    } else if (location.startsWith("/building/")) {
      const building_id = location.slice(10, 46);
      if (building_id.length === 0) {
        setBuilding(null);
        coreStore.setPreviousBuilding(null);
      } else {
        setBuilding(building_id);
      }
    } else {
      setBuilding(null);
    }
  }, [location, coreStore.isHydrated, userStore.isHydrated]);

  useEffect(() => {
    if (building) {
      coreStore.setPreviousBuilding(building);
      mqttStore.subscribe(`v2/building/${building}/#`);
      return () => mqttStore.unsubscribe(`v2/building/${building}/#`);
    }
  }, [building]);

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      logger("Visibility: Document hidden!");
      if (building) {
        mqttStore.unsubscribe(`v2/building/${building}/#`);
      }
    } else {
      logger("Visibility: Document restored!");
      if (building) {
        mqttStore.subscribe(`v2/building/${building}/#`);
      }
    }
  });

  useEffect(() => {
    // Used to make sure firebaseMessagingStore is included.
    firebaseMessagingStore;
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        <SnackbarProvider maxSnack={5}>
          <CssBaseline />
          <SnackbarUtilsConfigurator />
          <div>
            <Route path="/settings/" component={SettingsScreen} />
            {userStore.token && (
              <Route
                path="/building/:building_id/gateway/:uuid/"
                component={GatewayScreen}
              />
            )}
            {userStore.token && (
              <Route
                path="/building/:building_id/device/:uuid/"
                component={DeviceScreen}
              />
            )}
            {userStore.token && (
              <Route
                path="/building/:building_id/group/:uuid?/"
                component={GroupScreen}
              />
            )}
            {userStore.token && (
              <Route
                path="/building/:building_id/rule/:uuid?/"
                component={RuleScreen}
              />
            )}
            {userStore.token && (
              <Route
                path="/building/:building_id/room/:uuid?/"
                component={RoomScreen}
              />
            )}
            {userStore.token && (
              <Route
                path="/building/:building_id/state/:uuid?/"
                component={StateScreen}
              />
            )}
            {userStore.token && coreStore.isPluginEnabled("nordpool") && (
              <Route
                path="/building/:building_id/electricityContract/:uuid?/"
                component={ElectricityContractScreen}
              />
            )}
            {userStore.token && (
              <Route
                path="/building/:building_id/chart/:id?/"
                component={ChartEditScreen}
              />
            )}
            {userStore.token && (
              <Route
                path="/building/:uuid/settings/"
                component={BuildingSettingsScreen}
              />
            )}
            {userStore.token && (
              <Route path="/building/:uuid/" component={BuildingScreen} />
            )}
            {userStore.token && (
              <Route path="/building/" component={BuildingListScreen} />
            )}
            {process.env.NODE_ENV === "development" && (
              <Route path="/translation/" component={TranslationTestScreen} />
            )}
            <Route path="/login/" component={LoginScreen} />
            <Route path="/logout/" component={LogoutScreen} />
          </div>
          <UpdateNotification />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default App;
