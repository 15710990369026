import { Fab } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { observer } from "mobx-react-lite";
import { FC } from "react";

export interface IDeleteFab {
  confirmText: string;
  onDelete: () => void;
}

const DeleteFab: FC<IDeleteFab> = observer((props) => (
  <Fab
    onClick={() => {
      // eslint-disable-next-line no-alert
      if (window.confirm(props.confirmText)) {
        props.onDelete();
      }
    }}
    sx={{
      zIndex: 100000,
      color: "white",
      backgroundColor: "#b11212",
      position: "fixed",
      bottom: 16,
      left: 16,
    }}
  >
    <DeleteForeverIcon />
  </Fab>
));

export default DeleteFab;
