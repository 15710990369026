import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoute } from "wouter";
import AppBar from "../../../components/AppBar";
import CronForm from "../../../components/CronForm";
import DeleteFab from "../../../components/DeleteFab";
import SmallIconButton from "../../../components/SmallIconButton";
import {
  deleteElectricityContract,
  IElectricityContract,
  saveElectricityContract,
  TDistributionTariff,
  TEnergyContract,
} from "../../../services/plugin/Nordpool";
import electricityContractStore from "../../../stores/plugin/Nordpool";
import ErrorScreen from "../../ErrorScreen";

const ElectricityContractScreen = observer(() => {
  const { t } = useTranslation();
  const [, params] = useRoute(
    "/building/:building_id/electricityContract/:uuid?/",
  );
  const building: string =
    params && params.building_id ? params.building_id : "";
  const uuid: string = params && params.uuid ? params.uuid : "";
  const [contract, setContract] = useState<IElectricityContract>(
    electricityContractStore.find(uuid) ||
      ({
        uuid: "",
        building_id: building,
        contract_start: "",
        contract_end: null,
        energy_contract: 0,
        energy_monthly_price: "0.00",
        energy_price: "0.00",
        spot_price_vat: "0.00",
        distribution_tariff: 0,
        distribution_monthly_price: "0.00",
        distribution_price: "0.00",
        distribution_price_off_peak: "0.00",
        distribution_peak_times: "* * * * *",
      } as IElectricityContract),
  );
  const [isValid, setIsValid] = useState(false);
  const [distributionPeakTimes, setDistributionPeakTimes] = useState<string>(
    contract.distribution_peak_times,
  );

  useEffect(() => {
    if (contract.contract_start.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [contract]);

  const onSave = () => {
    saveElectricityContract(contract).then(() => window.history.back());
  };

  useEffect(() => {
    console.log(contract);
  }, [contract]);

  return (
    <Box>
      <AppBar
        title={
          contract.contract_start
            ? `${contract.contract_start} - ${contract.contract_end || ""}`
            : t("Add Electricity Contract")
        }
        withBack
        right={
          isValid && (
            <SmallIconButton
              variant="contained"
              color="secondary"
              text={t("Save")}
              startIcon={<SaveIcon />}
              onClick={onSave}
            />
          )
        }
      />
      <ErrorScreen>
        <Container maxWidth="xs" sx={{ marginTop: 2 }}>
          <Card>
            <CardContent>
              <Stack spacing={2} marginBottom={-1}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    label={t("Contract start")}
                    value={DateTime.fromISO(contract.contract_start)}
                    onChange={(time) => {
                      if (!time) {
                        return;
                      }
                      setContract({
                        ...contract,
                        contract_start: time.toISODate() as string,
                      });
                    }}
                  />
                  <DatePicker
                    label={t("Contract end (empty if not determined yet)")}
                    value={
                      contract.contract_end === null
                        ? null
                        : DateTime.fromISO(contract.contract_end)
                    }
                    slotProps={{
                      field: {
                        clearable: true,
                        onClear: () =>
                          setContract({ ...contract, contract_end: null }),
                      },
                    }}
                    onChange={(time) => {
                      if (!time) {
                        setContract({
                          ...contract,
                          contract_end: null,
                        });
                        return;
                      }
                      setContract({
                        ...contract,
                        contract_end: time.toISODate() as string,
                      });
                    }}
                  />
                </LocalizationProvider>
                <FormControl fullWidth>
                  <InputLabel>{t("Energy contract type")}</InputLabel>
                  <Select
                    label={t("Energy contract type")}
                    value={contract.energy_contract}
                    onChange={(event) => {
                      const value = event.target.value;
                      setContract({
                        ...contract,
                        energy_contract:
                          typeof value === "string"
                            ? (parseInt(value) as TEnergyContract)
                            : value,
                      });
                    }}
                  >
                    <MenuItem value={0}>{t("Fixed")}</MenuItem>
                    <MenuItem value={1}>{t("Spot")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label={t("Energy contract monthly price")}
                    value={contract.energy_monthly_price}
                    onChange={(event) => {
                      setContract({
                        ...contract,
                        energy_monthly_price: event.target.value,
                      });
                    }}
                    helperText={t(
                      "Monthly price of the energy contract in euros per month.",
                    )}
                  />
                </FormControl>
                {contract.energy_contract === 0 && (
                  <FormControl fullWidth>
                    <TextField
                      label={t("Energy contract energy price")}
                      helperText={t("Price of energy in cents per kWh.")}
                      value={contract.energy_price}
                      onChange={(event) => {
                        setContract({
                          ...contract,
                          energy_price: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                )}
                {contract.energy_contract === 1 && (
                  <FormControl fullWidth>
                    <TextField
                      label={t("Energy contract spot-margin")}
                      helperText={t("Spot-margin in cents per kWh.")}
                      value={contract.energy_price}
                      onChange={(event) => {
                        setContract({
                          ...contract,
                          energy_price: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                )}
                {contract.energy_contract === 1 && (
                  <FormControl fullWidth>
                    <TextField
                      label={t("Spot-price VAT %")}
                      helperText={t("VAT % to add on top of spot price.")}
                      value={contract.spot_price_vat}
                      onChange={(event) => {
                        setContract({
                          ...contract,
                          spot_price_vat: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                )}
                <FormControl fullWidth>
                  <InputLabel>{t("Distribution tariff type")}</InputLabel>
                  <Select
                    label={t("Distribution tariff type")}
                    value={contract.distribution_tariff}
                    onChange={(event) => {
                      const value = event.target.value;
                      setContract({
                        ...contract,
                        distribution_tariff:
                          typeof value === "string"
                            ? (parseInt(value) as TDistributionTariff)
                            : value,
                      });
                    }}
                  >
                    <MenuItem value={0}>{t("Fixed")}</MenuItem>
                    <MenuItem value={1}>{t("Time-of-day")}</MenuItem>
                    <MenuItem value={1}>{t("Time-of-year")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label={t("Distribution contract monthly price")}
                    value={contract.distribution_monthly_price}
                    onChange={(event) => {
                      setContract({
                        ...contract,
                        distribution_monthly_price: event.target.value,
                      });
                    }}
                    helperText={t(
                      "Monthly price of the distribution contract in euros per month.",
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label={
                      contract.distribution_tariff === 0
                        ? t("Distribution price")
                        : t("Distribution peak price")
                    }
                    helperText={
                      contract.distribution_tariff === 0
                        ? t("Price of distribution in cents per kWh.")
                        : t(
                            "Price of distribution in cents per kWh during peak times.",
                          )
                    }
                    value={contract.distribution_price}
                    onChange={(event) => {
                      setContract({
                        ...contract,
                        distribution_price: event.target.value,
                      });
                    }}
                  />
                </FormControl>
                {contract.distribution_tariff > 0 && (
                  <FormControl fullWidth>
                    <TextField
                      label={t("Distribution off-peak price")}
                      helperText={t(
                        "Price of distribution in cents per kWh during off-peak times.",
                      )}
                      value={contract.distribution_price_off_peak}
                      onChange={(event) => {
                        setContract({
                          ...contract,
                          distribution_price_off_peak: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                )}
                {contract.distribution_tariff > 0 && (
                  <Stack direction="column" spacing={2}>
                    <Typography variant="subtitle1" paddingBottom={0}>
                      {t("Distribution peak times")}
                    </Typography>
                    <CronForm
                      cronString={distributionPeakTimes}
                      onChange={(value, commit) => {
                        if (commit) {
                          setContract({
                            ...contract,
                            distribution_peak_times: value,
                          });
                        } else {
                          setDistributionPeakTimes(value);
                        }
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Container>
        {uuid && (
          <DeleteFab
            confirmText={t(
              'Do you really want to delete electricity contract "{{start}} - {{end}}"?',
              {
                start: contract.contract_start,
                end: contract.contract_end,
              },
            )}
            onDelete={() => {
              deleteElectricityContract(uuid).then(() => window.history.back());
            }}
          />
        )}
      </ErrorScreen>
    </Box>
  );
});

export default ElectricityContractScreen;
