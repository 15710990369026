import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { nanoid } from "nanoid";
import {
  ProviderContext,
  SnackbarKey,
  useSnackbar,
  VariantType,
} from "notistack";

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator = (): null => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg: string): void {
    this.toast(msg, "success");
  },
  warning(msg: string): void {
    this.toast(msg, "warning");
  },
  info(msg: string): void {
    this.toast(msg, "info");
  },
  error(msg: string): void {
    this.toast(msg, "error");
  },
  toast(msg: string, variant: VariantType = "default"): void {
    const key = nanoid();

    useSnackbarRef.enqueueSnackbar(msg, {
      key: key,
      variant: variant,
      style: { whiteSpace: "pre-line" },
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      action: (key: SnackbarKey) => (
        <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      ),
    });
  },
};
