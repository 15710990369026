import { Avatar, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import mainTheme from "../themes/main";
import TraitIcon, { ITraitIcon } from "./TraitIcon";

export type ITraitAvatar = ITraitIcon;

const useStyles = makeStyles(
  (theme: Theme) => ({
    avatar: {
      borderRadius: "16px",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
    },
  }),
  {
    defaultTheme: mainTheme,
  },
);

const TraitAvatar: FC<ITraitAvatar> = observer((props) => {
  const styles = useStyles();

  return (
    <Avatar className={styles.avatar} variant="circular">
      <TraitIcon
        traitClass={props.traitClass}
        unit={props.unit}
        value={props.value}
      />
    </Avatar>
  );
});

export default TraitAvatar;
