import traitStore from "../stores/TraitStore";
import getAuthenticatedInstance from "./authenticated";
import { BuildingChildInstance } from "./base";
export type TChartAggregate =
  | "mean"
  | "min"
  | "max"
  | "diff"
  | "non_negative_diff"
  | "";
export type TChartWindow =
  | "1m"
  | "5m"
  | "15m"
  | "30m"
  | "1h"
  | "3h"
  | "6h"
  | "12h"
  | "24h"
  | "";

interface ITrait extends BuildingChildInstance {
  trait_class: string;
  building_id: string;
  room_id: string | null;
  gateway_id: string | null;
  device_id: string;
  type: string;
  name: string;
  printable_name: string;
  nickname: string;
  channel: string | null;
  controllable: boolean;
  // control: ?
  value: number | string | null;
  unit: string;
  multiplier: number;
  disabled: boolean;
  last_updated: string;
  created: string;
}

export interface IActionTrait extends ITrait {
  actions: string;
}

export interface ITraitSettings {
  nickname?: string;
  controllable?: boolean;
  multiplier?: number;
  disabled?: boolean;
}

export interface IMultipleTraitHistoryRequest {
  start_time: string;
  end_time: string;
  aggregate?: TChartAggregate;
  building_id?: string;
  device_id?: string;
  disabled?: boolean;
  gateway?: string;
  room_id?: string;
  uuid?: string[];
  window?: TChartWindow;
}

export interface ITraitHistoryPoint {
  time: string;
  value: number;
}

export interface ITraitHistorySeries {
  uuid: string;
  name: string;
  printable_name: string;
  nickname: string;
  unit: string;
  points: ITraitHistoryPoint[];
}

export interface IMultipleTraitHistoryResponse {
  start_time: string;
  end_time: string;
  aggregate: TChartAggregate;
  window: TChartWindow;
  series: ITraitHistorySeries[];
}

export const setTraitValue = async (
  uuid: string,
  value: number | string,
): Promise<boolean> => {
  const response = await getAuthenticatedInstance().post(
    `/trait/${uuid}/set/`,
    { value: value },
  );
  return response.status === 200;
};

export const patchTrait = async (trait: ITrait): Promise<boolean> => {
  const response = await getAuthenticatedInstance().patch(
    `/trait/${trait.uuid}/`,
    {
      nickname: trait.nickname,
      controllable: trait.controllable,
      multiplier: trait.multiplier,
      disabled: trait.disabled,
    },
  );
  return response.status === 200;
};

export const deleteTrait = async (trait: ITrait): Promise<boolean> => {
  const response = await getAuthenticatedInstance().delete(
    `/trait/${trait.uuid}/`,
  );
  return response.status === 200;
};

export const calculateTraitDisplayValue = (
  traitClass: string,
  traits: ITrait[],
): number | string => {
  let traitsWithoutNulls: ITrait[] = traits.filter(
    (trait) => trait.value !== null,
  );

  if (traitClass === "ColorXY") {
    if (traits.length > 0 && typeof traits[0].value === "string") {
      return traits[0].value;
    }
    return "0.0,0.0";
  }

  if (traitsWithoutNulls.length === 0) {
    return 0;
  }

  if (traitClass === "Dimmer") {
    traitsWithoutNulls = traitsWithoutNulls.map((trait) => {
      const onOffTraits = traitStore.filter({
        device_id: trait.device_id,
        trait_class: "OnOff",
        channel: trait.channel,
      });
      // If matching OnOff is not found -> return trait
      if (onOffTraits.length === 0) {
        return trait;
      }
      // if matching OnOff is found, check if it's off
      const allOff =
        onOffTraits.filter(
          (onoff) =>
            onoff.value !== null &&
            typeof onoff.value === "number" &&
            onoff.value > 0,
        ).length === 0;
      // Report dimmer value as 0, if OnOff is off
      return allOff ? { ...trait, value: 0 } : trait;
    });
  }

  // Average by default
  let value: number =
    traitsWithoutNulls.reduce((a, b) => a + (b.value as number), 0.0) /
    traits.length;

  switch (traitClass) {
    case "OnOff":
    case "PowerOnBehavior":
    case "Occupancy":
    case "ACMode":
    case "Swing":
    case "FanSpeed":
    case "WaterLeak":
    case "ToshibaMeritA":
      // Get maximum value
      value = Math.max(
        ...traitsWithoutNulls.map((trait) => trait.value as number),
      );
      break;
    case "Power":
    case "Energy":
      // Calculate sum
      value = traitsWithoutNulls.reduce((a, b) => a + (b.value as number), 0.0);
      break;
    case "LightTemperature":
      // Round average to 100
      value = Math.round(value / 100) * 100;
      break;
    default:
      break;
  }
  return value;
};

export const fetchMultipleTraitHistory = async (
  query: IMultipleTraitHistoryRequest,
): Promise<IMultipleTraitHistoryResponse> => {
  const params = {
    ...query,
    uuid: query.uuid ? query.uuid.join(",") : undefined,
  };
  const response = await getAuthenticatedInstance().get("/trait/history/", {
    params: params,
  });
  return response.data;
};

export default ITrait;
