import getAuthenticatedInstance from "./authenticated";

interface IState {
  building_id: string;
  uuid: string;
  name: string;
  active: boolean;
}

export const activateState = async (state: IState): Promise<string> => {
  const response = await getAuthenticatedInstance().post(
    `/state/${state.uuid}/activate/`,
  );
  return response.data;
};

export const saveState = async (state: IState): Promise<IState> => {
  if (state.uuid.length > 0) {
    const response = await getAuthenticatedInstance().patch(
      `/state/${state.uuid}/`,
      state,
    );
    return response.data as IState;
  }
  const response = await getAuthenticatedInstance().post("/state/", state);
  return response.data as IState;
};

export const deleteState = async (uuid: string): Promise<IState> => {
  const response = await getAuthenticatedInstance().delete(`/state/${uuid}/`);
  return response.data as IState;
};

export default IState;
