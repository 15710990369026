import { IElectricityContract } from "../../services/plugin/Nordpool";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "../BuildingChildPagedStore";

export interface IElectricityContractFilter extends IBuildingChildFilter {}

class ElectricityContractStore extends BuildingChildPagedStore<
  IElectricityContract,
  IElectricityContractFilter
> {
  constructor() {
    super("plugin/nordpool/contract");
    this.persist(["instances"]);
  }

  sort = (a: IElectricityContract, b: IElectricityContract): 1 | -1 => {
    return a.contract_end === null
      ? -1
      : b.contract_end === null
        ? 1
        : a.contract_end < b.contract_end
          ? -1
          : 1;
  };
}

const electricityContractStore = new ElectricityContractStore();

export default electricityContractStore;
