import getAuthenticatedInstance from "../authenticated";
import { BuildingChildInstance } from "../base";

export type TEnergyContract = 0 | 1;
export type TDistributionTariff = 0 | 1 | 2;

export interface IElectricityContract extends BuildingChildInstance {
  contract_start: string;
  contract_end: string | null;
  energy_contract: TEnergyContract;
  energy_monthly_price: string;
  energy_price: string;
  spot_price_vat: string;
  distribution_tariff: TDistributionTariff;
  distribution_monthly_price: string;
  distribution_price: string;
  distribution_price_off_peak: string;
  distribution_peak_times: string;
}

export interface IPriceRequest {
  building_id: string;
  start_time?: string;
  end_time?: string;
  unit?: "c/kWh" | "€/MWh";
}

export interface IPricePoint {
  time: string;
  energy: string;
  distribution: string;
  total: string;
  spot?: string;
}

export interface IPriceResponse extends IPriceRequest {
  points: IPricePoint[];
}

export const saveElectricityContract = async (
  contract: IElectricityContract,
): Promise<IElectricityContract> => {
  if (contract.uuid.length > 0) {
    const response = await getAuthenticatedInstance().patch(
      `/plugin/nordpool/contract/${contract.uuid}/`,
      contract,
    );
    return response.data as IElectricityContract;
  }
  const response = await getAuthenticatedInstance().post(
    "/plugin/nordpool/contract/",
    contract,
  );
  return response.data as IElectricityContract;
};

export const deleteElectricityContract = async (
  uuid: string,
): Promise<IElectricityContract> => {
  const response = await getAuthenticatedInstance().delete(
    `/plugin/nordpool/contract/${uuid}/`,
  );
  return response.data as IElectricityContract;
};

export const fetchPrices = async (
  request: IPriceRequest,
): Promise<IPriceResponse> => {
  const response = await getAuthenticatedInstance().get(
    `/plugin/nordpool/contract/price/`,
    {
      params: request,
    },
  );
  return response.data as IPriceResponse;
};
