import { action, makeObservable, runInAction } from "mobx";
import { BuildingChildInstance } from "../services/base";
import { fetchAllPages } from "../services/utils";
import PagedStore, { IPagedStore, IPagedStoreFilter } from "./PagedStore";

export interface IBuildingChildFilter extends IPagedStoreFilter {
  building_id?: string;
}

export interface IBuildingChildPagedStore<
  T extends BuildingChildInstance,
  F extends IBuildingChildFilter,
> extends IPagedStore<T, F> {
  fetchFiltered(filters: F): Promise<void>;
}

class BuildingChildPagedStore<
    T extends BuildingChildInstance,
    F extends IBuildingChildFilter,
  >
  extends PagedStore<T, F>
  implements IBuildingChildPagedStore<T, F>
{
  constructor(endpoint: string) {
    super(endpoint);

    makeObservable(this, {
      fetchFiltered: action,
    });
  }

  fetchFiltered = async (filters: F): Promise<void> => {
    if (!this.endpoint) {
      throw Error("Store endpoint is not set!");
    }
    if (!filters || Object.keys(filters).length === 0) {
      throw Error("Cannot fetchFiltered, no filters!");
    }
    // Gather all the current UUIDs to list for deletion
    let uuidsToDelete = this.filter(filters).map((instance) => instance.uuid);
    // eslint-disable-next-line no-restricted-syntax
    const instances = [];
    for await (const instance of fetchAllPages<T>(
      this.endpoint,
      filters as Record<string, unknown> as { [key: string]: string },
    )) {
      instances.push(instance);
    }
    instances.forEach((instance) => {
      // Update or create instance
      const [, created] = this.update(instance);
      // If not created, remove it from the list of UUIDs selected for removal
      if (!created) {
        uuidsToDelete = uuidsToDelete.filter((uuid) => uuid !== instance.uuid);
      }
    });
    // Remove all instances of which UUID wasn't found in update
    runInAction(() => {
      this.instances = this.instances.filter(
        (instance) => uuidsToDelete.indexOf(instance.uuid) === -1,
      );
    });
  };
}

export default BuildingChildPagedStore;
