import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import BaseInstance from "../services/base";
import { fetchAllPages, fetchSingle } from "../services/utils";
import { IPersistedStore } from "./PersistedStore";
import { persist, typedKeys } from "./utils";

export interface IPagedStoreFilter {
  hiddenInstances?: boolean;
  // Not really a filter
  sorted?: boolean;
}

export interface IPagedStore<
  T extends BaseInstance,
  F extends IPagedStoreFilter,
> extends IPersistedStore {
  endpoint: string;
  instances: T[];
  hiddenInstances: string[];
  number_of_instances: number;
  sort: (a: T, b: T) => -1 | 1;
  update(instance: T): [T, boolean];
  delete(uuid: string): void;
  fetch(uuid: string): Promise<void>;
  fetchAll(): Promise<void>;
  find(uuid: string): T | undefined;
  findIndex(uuid: string): number;
  filter(filters: F): T[];
  filtered_number_of_instances(filters: F): number;
  hide(uuid: string): void;
  unHide(uuid: string): void;
}

class PagedStore<T extends BaseInstance, F extends IPagedStoreFilter>
  implements IPagedStore<T, F>
{
  endpoint = "";

  instances: T[] = [];

  hiddenInstances: string[] = [];

  isHydrated = false;

  constructor(endpoint: string) {
    this.endpoint = endpoint;

    makeObservable(this, {
      instances: observable,
      hiddenInstances: observable,
      number_of_instances: computed,
      isHydrated: observable,
      fetchAll: action,
      update: action,
      delete: action,
      hide: action,
      unHide: action,
    });
  }

  get number_of_instances(): number {
    return this.instances.length;
  }

  persist = (properties: string[] = ["instances", "hiddenInstances"]): void => {
    persist(this, this.endpoint, properties);
  };

  sort(a: T, b: T) {
    return a.uuid < b.uuid ? -1 : 1;
  }

  update = (instance: T): [T, boolean] => {
    const instanceIndex = this.findIndex(instance.uuid);
    if (instanceIndex === -1) {
      // If instance wasn't found -> push to end
      runInAction(() => this.instances.push(instance as T));
      return [instance as T, true];
    }
    // Otherwise update the instance
    runInAction(() => {
      this.instances[instanceIndex] = {
        ...this.find(instance.uuid),
        ...(instance as T),
      };
    });
    return [instance as T, false];
  };

  delete = async (uuid: string): Promise<void> => {
    runInAction(() => {
      this.instances = this.instances.filter(
        (instance) => instance.uuid !== uuid,
      );
    });
  };

  fetch = async (uuid: string): Promise<void> => {
    if (!this.endpoint) {
      throw Error("Store endpoint is not set!");
    }
    const instance = await fetchSingle<T>(this.endpoint, uuid);
    this.update(instance);
  };

  fetchAll = async (): Promise<void> => {
    if (!this.endpoint) {
      throw Error("Store endpoint is not set!");
    }
    // Gather all the current UUIDs to list for deletion
    let uuidsToDelete = this.instances.map((instance) => instance.uuid);
    // eslint-disable-next-line no-restricted-syntax
    for await (const instance of fetchAllPages<T>(this.endpoint)) {
      // Update or create instance
      const [, created] = this.update(instance);
      // If not created, remove it from the list of UUIDs selected for removal
      if (!created) {
        uuidsToDelete = uuidsToDelete.filter((uuid) => uuid !== instance.uuid);
      }
    }
    runInAction(() => {
      this.instances = this.instances.filter(
        (instance) => uuidsToDelete.indexOf(instance.uuid) === -1,
      );
    });
  };

  find = (uuid: string): T | undefined =>
    this.instances.find((instance) => instance.uuid === uuid);

  findIndex = (uuid: string): number =>
    this.instances.findIndex((instance) => instance.uuid === uuid);

  filter = (filters: F): T[] => {
    const sorted = filters.sorted === true;
    const includeHiddenInstances = filters.hiddenInstances !== false;
    const instances = [
      ...(includeHiddenInstances
        ? this.instances
        : this.instances.filter(
            (instance) => this.hiddenInstances.indexOf(instance.uuid) === -1,
          )),
    ].filter((instance) =>
      typedKeys(filters)
        .filter((key) => key !== "hiddenInstances" && key !== "sorted")
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .every((key) => instance[key] === filters[key]),
    );
    if (sorted) {
      return instances.sort(this.sort);
    }
    return instances;
  };

  filtered_number_of_instances = (filters: F): number =>
    this.filter(filters).length;

  hide = (uuid: string): void => {
    runInAction(() => this.hiddenInstances.push(uuid));
  };

  unHide = (uuid: string): void => {
    runInAction(() => {
      this.hiddenInstances = this.hiddenInstances.filter(
        (hiddenUUID) => hiddenUUID !== uuid,
      );
    });
  };
}

export default PagedStore;
