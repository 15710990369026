import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import ListIcon from "@mui/icons-material/List";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import SettingsIcon from "@mui/icons-material/Settings";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Container,
  IconButton,
  Paper,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useRoute } from "wouter";
import AppBar from "../components/AppBar";
import ChartList from "../components/ChartList";
import DeviceList from "../components/DeviceList";
import GroupList from "../components/GroupList";
import LogList from "../components/LogList";
import UpcomingPrices from "../components/plugin/nordpool/UpcomingPrices";
import RoomList from "../components/RoomList";
import buildingStore, { refreshBuilding } from "../stores/BuildingStore";
import coreStore from "../stores/CoreStore";
import mqttStore from "../stores/MQTTStore";
import electricityContractStore from "../stores/plugin/Nordpool";
import buildingPageUIStore from "../stores/UI/BuildingPageUIStore";
import nordpoolUiStore from "../stores/UI/plugin/nordpool/NordpoolUIStore";
import ErrorScreen from "./ErrorScreen";

const BuildingScreen = observer(() => {
  const { t } = useTranslation();
  const [, params] = useRoute("/building/:uuid/");
  const [, setLocation] = useLocation();
  const [tab, setTab] = useState(buildingPageUIStore.selectedTab);
  const uuid = params && params.uuid ? params.uuid : "";
  const building = buildingStore.find(uuid);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [tab]);

  useEffect(() => {
    if (building) {
      if (coreStore.shouldRefresh(building.uuid)) {
        console.log("Would refresh");
        refreshBuilding(building.uuid);
      }
    }
  }, [building]);

  return (
    <Box>
      <AppBar
        title={building?.name}
        withMenu
        withState
        setSearch={setSearch}
        right={
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={() => setLocation(`/building/${uuid}/settings/`)}
          >
            <SettingsIcon />
          </IconButton>
        }
      />
      <Container maxWidth="xl" sx={{ marginTop: 2 }}>
        <ErrorScreen>
          {coreStore.isPluginEnabled("nordpool") &&
            electricityContractStore.filtered_number_of_instances({
              building_id: uuid,
            }) > 0 &&
            nordpoolUiStore.energyPriceSettings.showEnergyPricesOnTop && (
              <UpcomingPrices building={uuid} />
            )}
          {mqttStore.settled && building && tab === 0 && (
            <GroupList building_id={uuid} search={search.toLowerCase()} />
          )}
          {mqttStore.settled && building && tab === 1 && (
            <RoomList building_id={uuid} search={search.toLowerCase()} />
          )}
          {mqttStore.settled && building && tab === 2 && (
            <DeviceList building_id={uuid} search={search.toLowerCase()} />
          )}
          {building && tab === 3 && <ChartList building_id={uuid} />}
          {building && tab === 4 && (
            <LogList building_id={uuid} search={search.toLowerCase()} />
          )}
        </ErrorScreen>
      </Container>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={tab}
          onChange={(event, newValue) => {
            setTab(newValue);
            buildingPageUIStore.setSelectedTab(newValue);
          }}
          sx={{
            overflowX: "auto",
          }}
        >
          <BottomNavigationAction
            label={t("Groups")}
            icon={<GroupWorkIcon />}
          />
          <BottomNavigationAction
            label={t("Rooms")}
            icon={<MeetingRoomIcon />}
          />
          <BottomNavigationAction
            label={t("Devices")}
            icon={<DeviceHubIcon />}
          />
          <BottomNavigationAction
            label={t("Charts")}
            icon={<ShowChartIcon />}
          />
          <BottomNavigationAction label={t("Logs")} icon={<ListIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
});

export default BuildingScreen;
