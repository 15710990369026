import { Alert, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ILogMessage } from "../services/Log";
import Timestamp from "./Timestamp";

const LogMessage: FC<ILogMessage> = (props: ILogMessage) => {
  let severity: "success" | "error" | "info" | "warning" | undefined = "error";
  const { level, message, logger, traceback, exception_type, time } = props;

  switch (level.toLowerCase()) {
    case "warning":
      severity = "warning";
      break;
    case "info":
      severity = "success";
      break;
    case "debug":
      severity = "info";
      break;
    case "notset":
      severity = "info";
      break;
    default:
      break;
  }
  // if (exception) {
  //   severity = "error";
  // }

  return (
    <Alert severity={severity} sx={{ paddingRight: 0, marginBottom: 1 }}>
      <Box sx={{ whiteSpace: "pre-wrap" }}>{message}</Box>
      {traceback && (
        <>
          <SyntaxHighlighter style={docco} language="pythonRepl">
            {`# ${exception_type}\n${traceback}`}
          </SyntaxHighlighter>
        </>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "100%",
          marginTop: 1,
        }}
      >
        <Typography fontSize="10pt">{logger}</Typography>
        <Timestamp
          timestamp={new Date(time).valueOf()}
          typographyProps={{ fontSize: "10pt" }}
        />
      </Stack>
    </Alert>
  );
};

export default LogMessage;
