import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import userStore from "../stores/UserStore";
import ErrorScreen from "./ErrorScreen";

const LogoutScreen = observer(() => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  useEffect(() => {
    userStore.logout().then(() => setLocation("/login/"));
  }, [setLocation]);

  return (
    <ErrorScreen>
      <div>
        {t("Logged out!")}
        <br />
        <a href="/login/">{t("Login")}</a>
      </div>
    </ErrorScreen>
  );
});

export default LogoutScreen;
