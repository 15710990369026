import { SvgIcon } from "@mui/material";
import React from "react";

const BlindIcon = (): React.ReactElement => (
  <SvgIcon>
    <path d="M16.165 5.289c-1.227-1.566-2.496-3.183-3.519-4.92a.748.748 0 00-1.291 0c-1.023 1.735-2.292 3.354-3.519 4.918C5.349 8.458 3 11.454 3 15c0 4.963 4.037 9 9 9s9-4.037 9-9c0-3.545-2.347-6.54-4.835-9.711zM12 22.5c-4.135 0-7.5-3.365-7.5-7.5 0-3.029 2.193-5.825 4.517-8.787C10.03 4.92 11.072 3.593 12 2.171c.928 1.422 1.97 2.75 2.984 4.043C17.306 9.177 19.5 11.974 19.5 15c0 4.136-3.364 7.5-7.5 7.5z" />
    <path d="M9.75 9C8.51 9 7.5 10.01 7.5 11.25s1.01 2.25 2.25 2.25S12 12.49 12 11.25 10.99 9 9.75 9zm0 3a.75.75 0 110-1.5.75.75 0 010 1.5zM14.25 16.5c-1.24 0-2.25 1.01-2.25 2.25S13.01 21 14.25 21s2.25-1.01 2.25-2.25-1.01-2.25-2.25-2.25zm0 3a.752.752 0 01-.75-.75c0-.413.338-.75.75-.75.413 0 .75.338.75.75 0 .413-.338.75-.75.75zM16.28 10.72a.75.75 0 00-1.061 0l-7.5 7.5a.75.75 0 001.06 1.061l7.5-7.5a.75.75 0 000-1.06z" />
  </SvgIcon>
);

export default BlindIcon;
