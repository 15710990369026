import AddIcon from "@mui/icons-material/Add";
import { Fab, Grid } from "@mui/material";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useLocation } from "wouter";
import chartUIStore from "../stores/UI/ChartUIStore";
import Chart from "./Chart";
import ChartButtonGroup from "./ChartButtonGroup";

export interface IChartList {
  building_id: string;
}

export interface IChartTimes {
  startTime: DateTime;
  endTime: DateTime;
}

const ChartList: FC<IChartList> = observer((props: IChartList) => {
  const [, setLocation] = useLocation();
  const charts = chartUIStore.filter({
    building_id: props.building_id,
    sorted: true,
  });
  const [chartTimes, setChartTimes] = useState<IChartTimes>({
    startTime: DateTime.now().startOf("day"),
    endTime: DateTime.now().endOf("day"),
  });

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={8} sm={5} md={3} lg={3} xl={2}>
          <ChartButtonGroup
            times={chartTimes}
            onSetChartTimes={(times) => setChartTimes(times)}
          />
        </Grid>
        {charts.map((chart) => (
          <Chart
            key={chart.id}
            chart={chart}
            startTime={chartTimes.startTime}
            endTime={chartTimes.endTime}
          />
        ))}
      </Grid>

      <Fab
        color="secondary"
        onClick={() => {
          setLocation(`/building/${props.building_id}/chart/`);
        }}
        sx={{
          zIndex: 100000,
          position: "fixed",
          bottom: 72,
          right: 16,
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
});

export default ChartList;
