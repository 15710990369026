import getAuthenticatedInstance from "./authenticated";
import { BuildingChildInstance } from "./base";

interface IRoom extends BuildingChildInstance {
  building_id: string;
  name: string;
}

export const saveRoom = async (room: IRoom): Promise<IRoom> => {
  if (room.uuid.length > 0) {
    const response = await getAuthenticatedInstance().patch(
      `/room/${room.uuid}/`,
      room,
    );
    return response.data as IRoom;
  }
  const response = await getAuthenticatedInstance().post("/room/", room);
  return response.data as IRoom;
};

export const deleteRoom = async (uuid: string): Promise<IRoom> => {
  const response = await getAuthenticatedInstance().delete(`/room/${uuid}/`);
  return response.data as IRoom;
};

export default IRoom;
