import {
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ILogMessage, TLogLevel, fetchLogsForBuilding } from "../services/Log";
import ChartButtonGroup from "./ChartButtonGroup";
import { IChartTimes } from "./ChartList";
import LogMessage from "./LogMessage";

export interface ILogList {
  building_id: string;
  search: string;
}

const LogList: FC<ILogList> = observer((props: ILogList) => {
  const { t } = useTranslation();
  const { building_id, search } = props;
  const [times, setTimes] = useState<IChartTimes>({
    startTime: DateTime.now().startOf("day"),
    endTime: DateTime.now().endOf("day"),
  });
  const [selectedLevels, setSelectedLevels] = useState<TLogLevel[]>([
    "CRITICAL",
    "ERROR",
    "WARNING",
    "INFO",
  ]);

  const [logMessages, setLogMessages] = useState<ILogMessage[]>([]);

  useEffect(() => {
    fetchLogsForBuilding({
      uuid: building_id,
      start: times.startTime,
      end: times.endTime,
      level: ["NOTSET", "DEBUG", "INFO", "WARNING", "ERROR", "CRITICAL"],
    }).then((messages) => setLogMessages(messages.reverse()));
  }, [times, building_id]);

  const messagesToShow = logMessages
    .filter((message) => selectedLevels.indexOf(message.level) > -1)
    .filter(
      (message) =>
        message.message.toLowerCase().includes(search) ||
        message.logger.toLowerCase().includes(search) ||
        message.traceback?.toLowerCase().includes(search) ||
        message.exception_type?.toLowerCase().includes(search),
    );

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={8} sm={5} md={3} lg={3} xl={2}>
        <Stack>
          <ChartButtonGroup
            times={times}
            onSetChartTimes={(times) => setTimes(times)}
          />
          <FormControl>
            <Select
              multiple
              value={selectedLevels}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setSelectedLevels(
                  (typeof value === "string"
                    ? value.split(",")
                    : value) as TLogLevel[],
                );
              }}
              renderValue={(selected) =>
                selected.map((level) => level.toLowerCase()).join(",")
              }
            >
              {["CRITICAL", "ERROR", "WARNING", "INFO", "DEBUG", "NOTSET"].map(
                (level) => (
                  <MenuItem key={level} value={level}>
                    <Checkbox
                      checked={selectedLevels.indexOf(level as TLogLevel) > -1}
                    />
                    <ListItemText primary={level.toLowerCase()} />
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {messagesToShow.length === 0 && (
          <LogMessage
            time={DateTime.now().toISO() as string}
            logger="raspautomation.ui"
            level="INFO"
            level_number={20}
            message={t("No log messages to show!")}
            exception={false}
            exception_type={null}
            traceback={null}
            tags={{}}
          />
        )}
        {messagesToShow.map((message, i) => (
          <LogMessage key={i} {...message} />
        ))}
      </Grid>
    </Grid>
  );
});

export default LogList;
