import getAuthenticatedInstance from "../authenticated";

export interface IGoogleHomeTraitSettings {
  trait: string;
  protection_type: 0 | 1 | 2;
  pin: string;
}

export const updateGoogleHomeTraitSettings = async (
  traitSettings: IGoogleHomeTraitSettings,
): Promise<IGoogleHomeTraitSettings | null> => {
  if (traitSettings.protection_type === 0) {
    await getAuthenticatedInstance().delete(
      `/plugin/googlehome/trait/${traitSettings.trait}/`,
    );
    return null;
  }
  try {
    const response = await getAuthenticatedInstance().patch(
      `/plugin/googlehome/trait/${traitSettings.trait}/`,
      traitSettings,
    );
    return response.data as IGoogleHomeTraitSettings;
  } catch (err) {
    const response = await getAuthenticatedInstance().post(
      "/plugin/googlehome/trait/",
      traitSettings,
    );
    return response.data as IGoogleHomeTraitSettings;
  }
};
