import { Theme, Tooltip, Typography, TypographyProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getEnvironmentConfig from "../environment/config";
import mainTheme from "../themes/main";

const ERROR_CUTOFF = getEnvironmentConfig().trait_error_cutoff;

export interface ITimestamp {
  timestamp: number;
  updateInterval?: number;
  useError?: boolean;
  typographyProps?: TypographyProps;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    timestamp: {
      color:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.3)"
          : "rgba(0, 0, 0, 0.6)",
    },
    timestampError: {
      color: theme.palette.error.main,
    },
  }),
  {
    defaultTheme: mainTheme,
  },
);

const Timestamp: FC<ITimestamp> = observer((props: ITimestamp) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [error, setError] = useState<boolean>(true);
  const [relativeTime, setRelativeTime] = useState<string | null>("");

  const updateRelativeTime = (time: number) => {
    const dateTime = DateTime.fromMillis(time);
    const diff = Math.abs(dateTime.diffNow("seconds").as("seconds"));
    setError(props.useError ? diff > ERROR_CUTOFF : false);
    if (diff < 5) {
      setRelativeTime(t("just now"));
    } else {
      setRelativeTime(dateTime.toRelative());
    }
  };

  useEffect(() => {
    updateRelativeTime(props.timestamp);

    const interval = setInterval(() => {
      updateRelativeTime(props.timestamp);
    }, props.updateInterval || 5000);

    return () => {
      clearInterval(interval);
    };
  }, [props.timestamp]);

  return (
    <Tooltip
      title={DateTime.fromMillis(props.timestamp).toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS,
      )}
      placement="top"
      arrow
    >
      <Typography
        className={error ? styles.timestampError : styles.timestamp}
        noWrap
        {...props.typographyProps}
      >
        {relativeTime}
      </Typography>
    </Tooltip>
  );
});

export default Timestamp;
