import { Box, Button, Container, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";

import AppBar from "../components/AppBar";
import Logo from "../components/Logo";
import VersionInfo from "../components/VersionInfo";
import buildingStore from "../stores/BuildingStore";
import coreStore from "../stores/CoreStore";
import ErrorScreen from "./ErrorScreen";

const BuildingListScreen = observer(() => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  useEffect(() => {
    coreStore.update();
    buildingStore.fetchAll();
  }, []);

  return (
    <Box>
      <AppBar title={t("Buildings")} withMenu />
      <ErrorScreen>
        <Container maxWidth="sm" sx={{ marginTop: 2 }}>
          <Stack spacing={2} marginTop={3}>
            <Logo />
            {buildingStore.filter({ sorted: true }).map((building) => (
              <Button
                key={building.uuid}
                color="primary"
                variant="contained"
                onClick={() => setLocation(`/building/${building.uuid}/`)}
              >
                {building.name}
              </Button>
            ))}
          </Stack>
          <VersionInfo />
        </Container>
      </ErrorScreen>
    </Box>
  );
});

export default BuildingListScreen;
