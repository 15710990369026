import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import AppBar from "../components/AppBar";
import VersionInfo from "../components/VersionInfo";
import FirebaseNotificationsCard from "../components/plugin/firebase_messaging/FirebaseNotificationsCard";
import UpcomingPricesSettings from "../components/plugin/nordpool/UpcomingPricesSettings";
import coreStore from "../stores/CoreStore";
import userStore from "../stores/UserStore";
import ErrorScreen from "./ErrorScreen";

const SettingsScreen = observer(() => {
  const { t } = useTranslation();
  const { language } = userStore;

  return (
    <Box>
      <AppBar title={t("Global settings")} withBack />
      <ErrorScreen>
        <Container maxWidth="xl" sx={{ marginTop: 2 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} xl={3}>
              <Card>
                <CardHeader title={t("Global settings")} />
                <CardContent>
                  <Stack spacing={2} marginTop={-1}>
                    <FormControl fullWidth>
                      <InputLabel>{t("Language")}</InputLabel>
                      <Select
                        label={t("Language")}
                        value={language}
                        onChange={(event) => {
                          const { value } = event.target;
                          userStore.setLanguage(value as "en" | "fi");
                        }}
                      >
                        <MenuItem value="en">{t("English")}</MenuItem>
                        <MenuItem value="fi">{t("Finnish")}</MenuItem>
                      </Select>
                    </FormControl>
                    {coreStore.isPluginEnabled("nordpool") && (
                      <UpcomingPricesSettings />
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {coreStore.isPluginEnabled("firebase_messaging") && (
              <Grid item xs={12} xl={3}>
                <FirebaseNotificationsCard />
              </Grid>
            )}
          </Grid>
        </Container>
        <VersionInfo />
      </ErrorScreen>
    </Box>
  );
});

export default SettingsScreen;
