import IState from "../services/State";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";
import stateUIStore from "./UI/StateUIStore";

class StateStore extends BuildingChildPagedStore<IState, IBuildingChildFilter> {
  constructor() {
    super("state");
    this.persist();
  }

  sort = (a: IState, b: IState): 1 | -1 => {
    return stateUIStore.findIndex(a.uuid) < stateUIStore.findIndex(b.uuid)
      ? -1
      : 1;
  };
}

const stateStore = new StateStore();

export default stateStore;
