import { action, makeObservable, observable, runInAction } from "mobx";
import { IGoogleHomeTraitSettings } from "../../services/plugin/GoogleHome";
import { fetchAllPages } from "../../services/utils";
import { IPersistedStore } from "../PersistedStore";
import { persist } from "../utils";

interface IGoogleHomeTraitSettingsStore extends IPersistedStore {
  instances: IGoogleHomeTraitSettings[];
  update(
    instance: IGoogleHomeTraitSettings,
  ): [IGoogleHomeTraitSettings, boolean];
  delete(trait: string): void;
  fetchAll(): Promise<void>;
  find(trait: string): IGoogleHomeTraitSettings | undefined;
  findIndex(trait: string): number;
}

class GoogleHomeTraitSettingsStore implements IGoogleHomeTraitSettingsStore {
  isHydrated = false;
  instances: IGoogleHomeTraitSettings[] = [];

  constructor() {
    makeObservable(this, {
      instances: observable,
      isHydrated: observable,
      update: action,
      delete: action,
      fetchAll: action,
      find: observable,
      findIndex: observable,
    });

    persist(this, "plugin.googlehome", ["instances"], undefined, 1);
  }

  update = (
    instance: IGoogleHomeTraitSettings,
  ): [IGoogleHomeTraitSettings, boolean] => {
    const instanceIndex = this.findIndex(instance.trait);
    if (instanceIndex === -1) {
      // If instance wasn't found -> push to end
      runInAction(() =>
        this.instances.push(instance as IGoogleHomeTraitSettings),
      );
      return [instance as IGoogleHomeTraitSettings, true];
    }
    // Otherwise update the instance
    runInAction(() => {
      this.instances[instanceIndex] = {
        ...this.find(instance.trait),
        ...(instance as IGoogleHomeTraitSettings),
      };
    });
    return [instance as IGoogleHomeTraitSettings, false];
  };

  delete = async (trait: string) => {
    runInAction(() => {
      this.instances = this.instances.filter(
        (instance) => instance.trait !== trait,
      );
    });
  };

  fetchAll = async () => {
    // Gather all the current UUIDs to list for deletion
    let uuidsToDelete = this.instances.map((instance) => instance.trait);
    // eslint-disable-next-line no-restricted-syntax
    for await (const instance of fetchAllPages<IGoogleHomeTraitSettings>(
      "plugin/googlehome/trait",
    )) {
      // Update or create instance
      const [, created] = this.update(instance);
      // If not created, remove it from the list of UUIDs selected for removal
      if (!created) {
        uuidsToDelete = uuidsToDelete.filter(
          (trait) => trait !== instance.trait,
        );
      }
    }
    runInAction(() => {
      this.instances = this.instances.filter(
        (instance) => uuidsToDelete.indexOf(instance.trait) === -1,
      );
    });
  };

  find = (trait: string): IGoogleHomeTraitSettings | undefined =>
    this.instances.find((instance) => instance.trait === trait);

  findIndex = (trait: string): number =>
    this.instances.findIndex((instance) => instance.trait === trait);
}

const googleHomeTraitSettingsStore = new GoogleHomeTraitSettingsStore();

export default googleHomeTraitSettingsStore;
