import getAuthenticatedInstance from "./authenticated";
import BaseInstance from "./base";

interface IBuilding extends BaseInstance {
  name: string;
}

export const saveBuilding = async (building: IBuilding): Promise<IBuilding> => {
  const response = await getAuthenticatedInstance().patch(
    `/building/${building.uuid}/`,
    building,
  );
  return response.data as IBuilding;
};

export default IBuilding;
