import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { FC, ReactNode, useState } from "react";
import slugify from "slugify";
import ITrait from "../services/Trait";
import Timestamp from "./Timestamp";
import TraitChip, { ITraitChipHide } from "./TraitChip";

const useStyles = makeStyles({
  cardHeader: {
    paddingBottom: "5px",
  },
  collapsedContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingTop: 0,
    paddingBottom: 0,
    "&:last-child": {
      paddingBottom: "5px",
    },
  },
});

export interface ITraitGroup {
  id: string;
  name?: string;
  traits: ITrait[];
}

export interface ITraitCard {
  id: string;
  title: string;
  subtitle?: string;
  traitGroups: ITraitGroup[];
  hiddenTraits: string[];
  actions?: ReactNode[];
  onHide?: (instance: ITraitChipHide) => void;
  onShow?: (instance: ITraitChipHide) => void;
  onDragEnd?: (result: DropResult) => void;
}

const TraitCard: FC<ITraitCard> = observer((props: ITraitCard) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(true);
  const { id, traitGroups, hiddenTraits, onHide, onShow, onDragEnd } = {
    ...props,
  };
  const lastUpdated = Math.max(
    ...traitGroups.flatMap((group) =>
      group.traits.flatMap((trait) =>
        new Date(trait?.last_updated || 0).valueOf(),
      ),
    ),
    -Infinity,
  );
  const firstUpdated = Math.min(
    ...traitGroups.flatMap((group) =>
      group.traits.flatMap((trait) =>
        new Date(trait?.last_updated || 0).valueOf(),
      ),
    ),
    Infinity,
  );

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
      <Card>
        <CardHeader
          title={props.title}
          subheader={props.subtitle}
          action={
            <IconButton onClick={() => setCollapsed(!collapsed)} size="large">
              {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
          }
          className={classes.cardHeader}
        />
        {collapsed ? (
          <CardContent className={classes.collapsedContent}>
            {traitGroups.map((traitGroup) => (
              <TraitChip
                key={traitGroup.id}
                id={traitGroup.id}
                name={traitGroup.name}
                hidden={hiddenTraits.indexOf(traitGroup.id) !== -1}
                onHide={onHide}
                onShow={onShow}
                traits={traitGroup.traits}
                full={!collapsed}
              />
            ))}
          </CardContent>
        ) : (
          <DragDropContext
            onDragEnd={(result: DropResult) => onDragEnd && onDragEnd(result)}
          >
            <Droppable droppableId={slugify(`${id}`)}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided.droppableProps}
                >
                  <CardContent className={classes.collapsedContent}>
                    {traitGroups.map((traitGroup, index) => (
                      <Draggable
                        key={
                          collapsed
                            ? slugify(`collapsed-${traitGroup.id || nanoid()}`)
                            : slugify(`${traitGroup.id || nanoid()}`)
                        }
                        draggableId={slugify(`${traitGroup.id || nanoid()}`)}
                        index={index}
                        isDragDisabled={collapsed}
                      >
                        {(providedDraggable) => (
                          <div
                            ref={providedDraggable.innerRef}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...providedDraggable.draggableProps}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...providedDraggable.dragHandleProps}
                            style={{
                              width: collapsed ? "auto" : "100%",
                              ...providedDraggable.draggableProps.style,
                            }}
                          >
                            <TraitChip
                              id={traitGroup.id}
                              name={traitGroup.name}
                              hidden={
                                hiddenTraits.indexOf(traitGroup.id) !== -1
                              }
                              onHide={onHide}
                              onShow={onShow}
                              traits={traitGroup.traits}
                              full={!collapsed}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </CardContent>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <CardActions sx={{ flexDirection: "column", alignItems: "flex-end" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <Timestamp timestamp={lastUpdated} useError />
            <Timestamp timestamp={firstUpdated} useError />
          </Stack>
          {!collapsed && props.actions && props.actions}
        </CardActions>
      </Card>
    </Grid>
  );
});

export default TraitCard;
