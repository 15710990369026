import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IStateFilter, ITimeFilter, ITraitValueFilter } from "../services/Rule";
import traitStore from "../stores/TraitStore";
import CronForm from "./CronForm";
import TranslatedTrait from "./TranslatedTrait";

export interface IFilterCard {
  building: string;
  filter: IStateFilter | ITraitValueFilter | ITimeFilter;
  onUpdate: (filter: IStateFilter | ITraitValueFilter | ITimeFilter) => void;
  remove: () => void;
}

export interface ITraitValueFilterCard extends IFilterCard {
  filter: ITraitValueFilter;
}

export interface ITimeFilterCard extends IFilterCard {
  filter: ITimeFilter;
}

const isTraitValueFilter = (
  filter: IStateFilter | ITraitValueFilter | ITimeFilter,
): filter is ITraitValueFilter => {
  return filter.filter_class === "TraitValueFilter";
};

const isTimeFilter = (
  filter: IStateFilter | ITraitValueFilter | ITimeFilter,
): filter is ITimeFilter => {
  return filter.filter_class === "TimeFilter";
};

const TraitValueFilterContent: FC<ITraitValueFilterCard> = observer(
  (props: ITraitValueFilterCard) => {
    const { t } = useTranslation();
    const { building, filter, onUpdate } = props;
    const { trait_id, type, value } = filter;
    const [trait, setTrait] = useState<string>(trait_id);
    const [traitClass, setTraitClass] = useState<string>(
      traitStore.find(trait)?.trait_class || "Temperature",
    );
    const traitClasses = traitStore.getSortedTraitClasses(
      traitStore.filter({ building_id: building }),
    );
    const filteredTraits = traitStore.filter({
      building_id: building,
      trait_class: traitClass,
      sorted: true,
    });
    const [tempValue, setTempValue] = useState(value);

    return (
      <>
        <FormControl fullWidth>
          <InputLabel>{t("Class")}</InputLabel>
          <Select
            variant="outlined"
            label={t("Class")}
            value={traitClass}
            renderValue={(x) => <TranslatedTrait traitClass={x} />}
            onChange={(event) => {
              if (event.target.value !== traitClass) {
                setTrait("");
              }
              setTraitClass(event.target.value);
            }}
          >
            {traitClasses.map((traitclass) => (
              <MenuItem key={traitclass} value={traitclass}>
                <TranslatedTrait traitClass={traitclass} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("Trait")}</InputLabel>
          <Select
            variant="outlined"
            label={t("Trait")}
            value={trait}
            renderValue={(x) => traitStore.getTraitText(x)}
            onChange={(event) => {
              setTrait(event.target.value);
            }}
          >
            {filteredTraits.map((x) => (
              <MenuItem key={x.uuid} value={x.uuid}>
                {traitStore.getTraitText(x.uuid)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("Type")}</InputLabel>
          <Select
            variant="outlined"
            label={t("Type")}
            value={type}
            renderValue={(x) => (x === 0 ? t("value under") : t("value over"))}
            onChange={(event) => {
              onUpdate({
                ...filter,
                type: parseInt(event.target.value.toString(), 10) === 1 ? 1 : 0,
              });
            }}
          >
            <MenuItem value={0}>{t("value under")}</MenuItem>
            <MenuItem value={1}>{t("value over")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          type="number"
          label={t("Value")}
          value={tempValue.toString()}
          onChange={(event) => {
            setTempValue(parseFloat(event.target.value));
          }}
          onBlur={() => {
            if (Number.isFinite(tempValue)) {
              onUpdate({
                ...filter,
                value: tempValue,
              });
            }
          }}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filter.only_on_first_trigger}
                onChange={(event) => {
                  onUpdate({
                    ...filter,
                    only_on_first_trigger: event.target.checked,
                  });
                }}
              />
            }
            label={t("Only on first Trigger?")}
          />
        </FormGroup>
      </>
    );
  },
);

const TimeFilterContent: FC<ITimeFilterCard> = (props: ITimeFilterCard) => {
  const { filter, onUpdate } = props;
  const [cronString, setCronString] = useState<string>(filter.cron);

  return (
    <CronForm
      cronString={cronString}
      onChange={(cronString: string, commit: boolean) => {
        if (commit) {
          onUpdate({
            ...filter,
            cron: cronString,
          });
        } else {
          setCronString(cronString);
        }
      }}
    />
  );
};

const FilterCard: FC<IFilterCard> = observer((props: IFilterCard) => {
  const { t } = useTranslation();
  const { building, filter, onUpdate, remove } = props;

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} marginBottom={-1}>
          <FormControl fullWidth>
            <InputLabel>{t("Class")}</InputLabel>
            <Select
              variant="outlined"
              label={t("Class")}
              value={filter.filter_class}
              onChange={(event) => {
                if (
                  event.target.value === "TraitValueFilter" &&
                  filter.filter_class !== "TraitValueFilter"
                ) {
                  onUpdate({
                    filter_class: "TraitValueFilter",
                    only_on_first_trigger: false,
                    trait_id: "",
                    type: 0,
                    value: 0,
                  });
                } else if (
                  event.target.value === "TimeFilter" &&
                  filter.filter_class !== "TimeFilter"
                ) {
                  onUpdate({
                    filter_class: "TimeFilter",
                    only_on_first_trigger: false,
                    cron: "* * * * *",
                  });
                }
              }}
            >
              <MenuItem value="TraitValueFilter">
                {t("TraitValueFilter")}
              </MenuItem>
              <MenuItem value="TimeFilter">{t("TimeFilter")}</MenuItem>
            </Select>
          </FormControl>
          {isTraitValueFilter(filter) && (
            <TraitValueFilterContent
              building={building}
              onUpdate={onUpdate}
              remove={remove}
              filter={filter}
            />
          )}
          {isTimeFilter(filter) && (
            <TimeFilterContent
              building={building}
              onUpdate={onUpdate}
              remove={remove}
              filter={filter}
            />
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteForeverIcon />}
          onClick={() => remove()}
        >
          {t("Delete")}
        </Button>
      </CardActions>
    </Card>
  );
});

export default FilterCard;
