import getAuthenticatedInstance from "../authenticated";

export interface IFirebaseMessagingSubscription {
  id: number;
  fcm_token: string;
  building: string;
}

export const updateFirebaseMessagingSubscriptions = async (
  token: string,
): Promise<IFirebaseMessagingSubscription[]> => {
  const response = await getAuthenticatedInstance().get(
    `/plugin/firebase_messaging/?fcm_token=${token}`,
  );
  return response.data as IFirebaseMessagingSubscription[];
};

export const subscribeToBuilding = async (
  token: string,
  building: string,
): Promise<IFirebaseMessagingSubscription> => {
  const response = await getAuthenticatedInstance().post(
    "/plugin/firebase_messaging/",
    { fcm_token: token, building: building },
  );
  return response.data as IFirebaseMessagingSubscription;
};

export const unsubscribeFromBuilding = async (id: number): Promise<void> => {
  await getAuthenticatedInstance().delete(`/plugin/firebase_messaging/${id}/`);
};
