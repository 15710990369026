import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TextField } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { isSmall } from "../utils";

export interface ISearchProps {
  setSearch: (value: string) => void;
  onFocus?: () => void;
  onBlur?: (searchLength: number) => void;
}

const Search: FC<ISearchProps> = (props: ISearchProps) => {
  const { t } = useTranslation();
  const smallDevice = isSmall();
  const [focused, setFocused] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  return (
    <TextField
      variant="outlined"
      placeholder={smallDevice && !focused ? "" : t("Search")}
      value={search}
      onChange={(event) => {
        props.setSearch(event.target.value);
        setSearch(event.target.value);
      }}
      onFocus={() => {
        setFocused(true);
        props.onFocus && props.onFocus();
      }}
      onBlur={() => {
        setFocused(search.length !== 0);
        props.onBlur && props.onBlur(search.length);
      }}
      InputProps={{
        startAdornment: (
          <IconButton
            sx={{
              pointerEvents: "none",
              paddingLeft: 0,
              paddingTop: smallDevice ? 0.5 : 1,
              paddingBottom: smallDevice ? 0.5 : 1,
            }}
          >
            <SearchIcon />
          </IconButton>
        ),
        endAdornment:
          focused && search.length > 0 ? (
            <IconButton
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: smallDevice ? 0.5 : 1,
                paddingBottom: smallDevice ? 0.5 : 1,
              }}
              onClick={() => {
                props.setSearch("");
                setSearch("");
                setFocused(false);
                props.onBlur && props.onBlur(0);
              }}
            >
              <ClearIcon />
            </IconButton>
          ) : undefined,
        sx: {
          paddingLeft: 1,
        },
      }}
      inputProps={{
        sx: {
          padding: 0,
        },
      }}
      sx={{
        paddingLeft: smallDevice ? 1 : undefined,
        paddingRight: smallDevice ? 1 : undefined,
        width: smallDevice ? (focused ? "100%" : "6ch") : "auto",
      }}
    />
  );
};

export default Search;
