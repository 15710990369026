import { observer } from "mobx-react-lite";
import { FC } from "react";
import i18n from "../translations/i18n";

export interface ITranslatedTrait {
  traitClass: string;
}

export const translateTrait = (traitClass: string): string => {
  const t = i18n.t.bind(i18n);
  switch (traitClass) {
    // IO
    case "OnOff":
      return t("On/Off");
    case "PowerOnBehavior":
      return t("Power-on behavior");
    case "Dimmer":
      return t("Dimmer");
    case "LightTemperature":
      return t("Light temperature");
    case "Blind":
      return t("Blind");
    case "Action":
      return t("Action");
    case "Contact":
      return t("Contact");
    case "FanSpeed":
      return t("Fan speed");
    case "ACMode":
      return t("Operating mode");
    case "Swing":
      return t("Swing");
    case "ColorXY":
      return t("Color");
    // Sensor
    case "Temperature":
      return t("Temperature");
    case "Humidity":
      return t("Humidity");
    case "BatteryLevel":
      return t("Battery level");
    case "Voltage":
      return t("Voltage");
    case "Current":
      return t("Current");
    case "Power":
      return t("Power");
    case "Energy":
      return t("Energy");
    case "Frequency":
      return t("Frequency");
    case "Conductivity":
      return t("Conductivity");
    case "Illuminance":
      return t("Illuminance");
    case "SoilMoisture":
      return t("Soil moisture");
    case "PressureBar":
      return t("Pressure (bar)");
    case "PressurehPa":
      return t("Pressure (hPa)");
    case "LinkQuality":
      return t("Link quality");
    case "Occupancy":
      return t("Occupancy");
    case "VOC":
      return t("VOC");
    case "CO2":
      return t("CO₂");
    case "Formaldehyd":
      return t("Formaldehyd");
    case "PM25":
      return t("PM2.5");
    case "WindSpeed":
      return t("Wind speed");
    case "WindDirection":
      return t("Wind direction");
    case "CloudCoverage":
      return t("Cloud coverage");
    // Plugin
    case "ToshibaPowerSelection":
      return t("Power selection"); // (plugin.ac_toshiba)
    case "ToshibaMeritA":
      return t("Merit"); // (plugin.ac_toshiba)
    case "TransferRate":
      return t("Transfer rate"); // (plugin.unifi)
    case "UnifiPoEPowerCycle":
      return t("Power cycle"); // (plugin.unifi)
    case "Range":
      return t("Range");
    case "RadarSensitivity":
      return t("Radar sensitivity");
    case "Delay":
      return t("Delay");
    case "WaterLeak":
      return t("Water leak");
    case "CameraImage":
      return t("Camera image");
    case "ChargingState":
      return t("Charging state");
    case "Distance":
      return t("Distance");
    case "ImageCaptureInterval":
      return t("Image capture interval");
    case "Lock":
      return t("Lock");
    case "RemainingTime":
      return t("Remaining time");
    case "SpeedKmPerH":
      return t("Speed");
    default:
      return traitClass;
  }
};

const TranslatedTrait: FC<ITranslatedTrait> = observer(
  (props: ITranslatedTrait) => {
    const { traitClass } = props;
    return <span>{translateTrait(traitClass)}</span>;
  },
);

export default TranslatedTrait;
