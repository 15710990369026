import getAuthenticatedInstance from "./authenticated";
import { BuildingChildInstance } from "./base";

export interface ITraitGroup {
  uuid: string;
  name: string;
  group_as_one: boolean;
  traits: string[];
}

interface IGroup extends BuildingChildInstance {
  name: string;
  traitgroups: ITraitGroup[];
}

export const saveGroup = async (group: IGroup): Promise<IGroup> => {
  if (group.uuid.length > 0) {
    const response = await getAuthenticatedInstance().patch(
      `/group/${group.uuid}/`,
      group,
    );
    return response.data as IGroup;
  }
  const response = await getAuthenticatedInstance().post("/group/", group);
  return response.data as IGroup;
};

export const deleteGroup = async (uuid: string): Promise<IGroup> => {
  const response = await getAuthenticatedInstance().delete(`/group/${uuid}/`);
  return response.data as IGroup;
};

export default IGroup;
