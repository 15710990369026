import { Settings } from "luxon";
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { fetchUserDetails, login } from "../services/User";
import i18n from "../translations/i18n";
import { clearStores, persist } from "./utils";

export interface IUserStore {
  pk: number;
  email: string;
  first_name: string;
  last_name: string;
  token: string | null;
  language: "en" | "fi";
  isHydrated: boolean;
  logoutCallbacks: (() => Promise<unknown>)[];
  addLogoutCallback: (target: () => Promise<unknown>) => void;
}

class UserStore implements IUserStore {
  pk = 0;
  email = "";
  first_name = "";
  last_name = "";
  token: string | null = null;
  language: "en" | "fi" = "en";
  isHydrated = false;
  logoutCallbacks: (() => Promise<unknown>)[] = [];

  constructor() {
    makeObservable(this, {
      pk: observable,
      email: observable,
      first_name: observable,
      last_name: observable,
      token: observable,
      language: observable,
      isHydrated: observable,
      logoutCallbacks: observable,
      name: computed,
      short_name: computed,
      login: action,
      logout: action,
      fetchUserDetails: action,
      setLanguage: action,
      addLogoutCallback: action,
    });

    persist(
      this,
      "user",
      ["pk", "email", "first_name", "last_name", "token", "language"],
      undefined,
      1,
    );

    autorun(() => {
      i18n.changeLanguage(this.language);
      Settings.defaultLocale = this.language;
    });
  }

  get name(): string {
    if (!this.first_name && !this.last_name) {
      return this.email;
    }
    if (this.first_name && this.last_name) {
      return `${this.first_name} ${this.last_name}`;
    }
    return this.first_name ? this.first_name : this.last_name;
  }

  get short_name(): string {
    if (!this.first_name && !this.last_name) {
      return this.email;
    }
    return this.first_name ? this.first_name : this.last_name;
  }

  async login(email: string, password: string): Promise<boolean> {
    this.pk = 0;
    this.email = "";
    this.first_name = "";
    this.last_name = "";
    this.token = null;

    try {
      const response = await login(email, password);
      runInAction(() => {
        this.email = email;
        this.token = response.key;
        this.fetchUserDetails();
      });
      return response.key !== "";
    } catch (e) {
      runInAction(() => {
        this.token = null;
      });
      return false;
    }
  }

  async logout(): Promise<PromiseSettledResult<false | void>[]> {
    const promises = this.logoutCallbacks.map(async (target) => await target());
    await Promise.allSettled(promises);
    runInAction(() => {
      this.pk = 0;
      this.email = "";
      this.first_name = "";
      this.last_name = "";
      this.token = null;
    });
    return clearStores();
  }

  fetchUserDetails() {
    fetchUserDetails().then((response) => {
      runInAction(() => {
        this.pk = response.pk;
        this.email = response.email;
        this.first_name = response.first_name;
        this.last_name = response.last_name;
      });
    });
  }

  setLanguage = (language: "en" | "fi") => {
    runInAction(() => {
      this.language = language;
    });
  };

  addLogoutCallback = (target: () => Promise<unknown>) => {
    if (this.logoutCallbacks.includes(target)) {
      return;
    }
    runInAction(() => {
      this.logoutCallbacks.push(target);
    });
  };
}

const userStore = new UserStore();
export default userStore;
