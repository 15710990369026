import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Popper,
  rgbToHex,
  Slider,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ColorConverter from "../ColorConverter";
import mainTheme from "../themes/main";
import TraitIcon from "./TraitIcon";
import TraitValueText from "./TraitValue";

export interface ITraitPopperContent {
  name?: string | React.ReactNode;
  traitClass: string;
  value: number | string;
  unit: string;
  onSet: (value: number | string) => void;
}

export interface ITraitPopper extends ITraitPopperContent {
  anchor: (EventTarget & HTMLDivElement) | null;
  open: boolean;
  setOpen: (value: boolean) => void;
  id?: string;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    popperBox: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage:
        "linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))",
      marginBottom: "-3px",
      padding: "15px",
      borderRadius: "15px",
    },
    slider: {
      width: "75vw",
      maxWidth: "100%",
      marginLeft: "5px",
      marginRight: "5px",
      marginBottom: "-10px",
    },
  }),
  {
    defaultTheme: mainTheme,
  },
);

export const TraitPopperContent: FC<ITraitPopperContent> = observer((props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();
  const isLargeDevice = useMediaQuery(theme.breakpoints.up("sm"));
  const { name, traitClass, value, unit, onSet } = { ...props };
  const [newValue, setNewValue] = useState(value);

  let defaultValue = value;
  if (traitClass === "ColorXY") {
    const { r, g, b } = ColorConverter.xyBriToRgb(
      ...newValue
        .toString()
        .split(",")
        .map((x) => parseFloat(x)),
      255,
    );
    defaultValue = rgbToHex(`rgb(${r}, ${g}, ${b})`).slice(0, 7);
  }

  switch (traitClass) {
    case "OnOff":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup size="large" color="primary">
            <Button
              variant={value === 0 ? "contained" : "outlined"}
              onClick={() => onSet(0)}
            >
              {t("turn off")}
            </Button>
            <Button
              variant={value === 0 ? "outlined" : "contained"}
              onClick={() => onSet(1)}
            >
              {t("turn on")}
            </Button>
          </ButtonGroup>
        </>
      );
    case "PowerOnBehavior":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup size="large" color="primary">
            <Button
              variant={value === 0 ? "contained" : "outlined"}
              onClick={() => onSet(0)}
            >
              {t("turn off")}
            </Button>
            <Button
              variant={value === 1 ? "contained" : "outlined"}
              onClick={() => onSet(1)}
            >
              {t("turn on")}
            </Button>
            <Button
              variant={value === 2 ? "contained" : "outlined"}
              onClick={() => onSet(2)}
            >
              {t("previous state")}
            </Button>
          </ButtonGroup>
        </>
      );
    case "Dimmer":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <TraitIcon traitClass={traitClass} unit={unit} value={newValue} />
          <Slider
            className={styles.slider}
            value={typeof newValue === "number" ? newValue : 0.0}
            min={0}
            max={100}
            step={5}
            valueLabelDisplay="auto"
            valueLabelFormat={(v: number) => (
              <TraitValueText traitClass={traitClass} value={v} unit={unit} />
            )}
            onChange={(event, sliderValue) =>
              setNewValue(sliderValue as number)
            }
            onChangeCommitted={(event, sliderValue) => {
              onSet(sliderValue as number);
            }}
          />
        </>
      );
    case "LightTemperature":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <TraitIcon traitClass={traitClass} unit={unit} value={newValue} />
          <Slider
            className={styles.slider}
            value={typeof newValue === "number" ? newValue : 0.0}
            min={2000}
            max={6500}
            step={100}
            valueLabelDisplay="auto"
            valueLabelFormat={(v: number) => (
              <TraitValueText traitClass={traitClass} value={v} unit={unit} />
            )}
            onChange={(event, sliderValue) =>
              setNewValue(sliderValue as number)
            }
            onChangeCommitted={(event, sliderValue) => {
              onSet(sliderValue as number);
            }}
          />
        </>
      );
    case "Blind":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <TraitIcon traitClass={traitClass} unit={unit} value={newValue} />
          <Slider
            className={styles.slider}
            value={typeof newValue === "number" ? newValue : 0.0}
            min={0}
            max={100}
            step={5}
            valueLabelDisplay="auto"
            valueLabelFormat={(v: number) => (
              <TraitValueText traitClass={traitClass} value={v} unit={unit} />
            )}
            onChange={(event, sliderValue) =>
              setNewValue(sliderValue as number)
            }
            onChangeCommitted={(event, sliderValue) => {
              onSet(sliderValue as number);
            }}
          />
        </>
      );
    case "Temperature":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup size="large" color="primary">
            <Button
              variant="contained"
              onClick={() => {
                setNewValue(
                  (typeof newValue === "number" ? newValue : 0.0) - 1,
                );
                onSet((typeof newValue === "number" ? newValue : 0.0) - 1);
              }}
            >
              -
            </Button>
            <Button disabled style={{ color: "white" }}>
              {`${newValue} ${unit}`}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setNewValue(
                  (typeof newValue === "number" ? newValue : 0.0) + 1,
                );
                onSet((typeof newValue === "number" ? newValue : 0.0) + 1);
              }}
            >
              +
            </Button>
          </ButtonGroup>
        </>
      );
    case "ACMode":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup
            size="large"
            color="primary"
            orientation={isLargeDevice ? "horizontal" : "vertical"}
          >
            <Button
              variant={value === 0 ? "contained" : "outlined"}
              onClick={() => onSet(0)}
            >
              {t("cool")}
            </Button>
            <Button
              variant={value === 1 ? "contained" : "outlined"}
              onClick={() => onSet(1)}
            >
              {t("heat")}
            </Button>
            <Button
              variant={value === 2 ? "contained" : "outlined"}
              onClick={() => onSet(2)}
            >
              {t("fan")}
            </Button>
            <Button
              variant={value === 3 ? "contained" : "outlined"}
              onClick={() => onSet(3)}
            >
              {t("dry")}
            </Button>
            <Button
              variant={value === 99 ? "contained" : "outlined"}
              onClick={() => onSet(99)}
            >
              {t("auto")}
            </Button>
          </ButtonGroup>
        </>
      );
    case "FanSpeed":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup
            size="large"
            color="primary"
            orientation={isLargeDevice ? "horizontal" : "vertical"}
          >
            <Button
              variant={value === 0 ? "contained" : "outlined"}
              onClick={() => onSet(0)}
            >
              {t("quiet")}
            </Button>
            <Button
              variant={value === 1 ? "contained" : "outlined"}
              onClick={() => onSet(1)}
            >
              {t("low")}
            </Button>
            <Button
              variant={value === 2 ? "contained" : "outlined"}
              onClick={() => onSet(2)}
            >
              {t("medium")}
            </Button>
            <Button
              variant={value === 3 ? "contained" : "outlined"}
              onClick={() => onSet(3)}
            >
              {t("high")}
            </Button>
            <Button
              variant={value === 99 ? "contained" : "outlined"}
              onClick={() => onSet(99)}
            >
              {t("auto")}
            </Button>
          </ButtonGroup>
        </>
      );
    case "Swing":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup
            size="large"
            color="primary"
            orientation={isLargeDevice ? "horizontal" : "vertical"}
          >
            <Button
              variant={value === 0 ? "contained" : "outlined"}
              onClick={() => onSet(0)}
            >
              {t("stopped")}
            </Button>
            <Button
              variant={value === 1 ? "contained" : "outlined"}
              onClick={() => onSet(1)}
            >
              {t("swing")}
            </Button>
            <Button
              variant={value === 2 ? "contained" : "outlined"}
              onClick={() => onSet(2)}
            >
              {t("fixed 1")}
            </Button>
            <Button
              variant={value === 3 ? "contained" : "outlined"}
              onClick={() => onSet(3)}
            >
              {t("fixed 2")}
            </Button>
            <Button
              variant={value === 4 ? "contained" : "outlined"}
              onClick={() => onSet(4)}
            >
              {t("fixed 3")}
            </Button>
            <Button
              variant={value === 5 ? "contained" : "outlined"}
              onClick={() => onSet(5)}
            >
              {t("fixed 4")}
            </Button>
            <Button
              variant={value === 6 ? "contained" : "outlined"}
              onClick={() => onSet(6)}
            >
              {t("fixed 5")}
            </Button>
          </ButtonGroup>
        </>
      );
    case "BatteryLevel":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <TraitIcon traitClass={traitClass} unit={unit} value={newValue} />
          <Slider
            className={styles.slider}
            value={typeof newValue === "number" ? newValue : 0.0}
            min={0}
            max={100}
            step={10}
            valueLabelDisplay="auto"
            valueLabelFormat={(v: number) => (
              <TraitValueText traitClass={traitClass} value={v} unit={unit} />
            )}
            onChange={(event, sliderValue) =>
              setNewValue(sliderValue as number)
            }
            onChangeCommitted={(event, sliderValue) => {
              onSet(sliderValue as number);
            }}
          />
        </>
      );
    case "ColorXY":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          {name && <Typography variant="subtitle2">{newValue}</Typography>}
          <input
            type="color"
            defaultValue={defaultValue}
            onChange={(e) => {
              const hexColor = e.target.value;
              const red = parseInt(hexColor.substr(1, 2), 16);
              const green = parseInt(hexColor.substr(3, 2), 16);
              const blue = parseInt(hexColor.substr(5, 2), 16);

              const { x, y } = ColorConverter.rgbToXy(red, green, blue);
              setNewValue(`${x.toFixed(4)},${y.toFixed(4)}`);
            }}
          />
          <Button onClick={() => onSet(newValue)}>{t("Set")}</Button>
        </>
      );
    case "ToshibaMeritA":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup
            size="large"
            color="primary"
            orientation={isLargeDevice ? "horizontal" : "vertical"}
          >
            <Button
              variant={value === 1 ? "contained" : "outlined"}
              onClick={() => onSet(1)}
            >
              {t("high power")}
            </Button>
            <Button
              variant={value === 3 ? "contained" : "outlined"}
              onClick={() => onSet(3)}
            >
              {t("eco")}
            </Button>
            <Button
              variant={value === 2 ? "contained" : "outlined"}
              onClick={() => onSet(2)}
            >
              {t("silent")}
            </Button>
            <Button
              variant={value === 8 ? "contained" : "outlined"}
              onClick={() => onSet(8)}
            >
              {t("extra silent")}
            </Button>
            <Button
              variant={value === 9 ? "contained" : "outlined"}
              onClick={() => onSet(9)}
            >
              {t("off")}
            </Button>
          </ButtonGroup>
        </>
      );
    case "ToshibaPowerSelection":
      return (
        <>
          <Typography variant="subtitle2">{name || t("Power")}</Typography>
          <ButtonGroup
            size="large"
            color="primary"
            orientation={isLargeDevice ? "horizontal" : "vertical"}
          >
            <Button
              variant={value === 50 ? "contained" : "outlined"}
              onClick={() => onSet(50)}
            >
              {t("50 %")}
            </Button>
            <Button
              variant={value === 75 ? "contained" : "outlined"}
              onClick={() => onSet(75)}
            >
              {t("75 %")}
            </Button>
            <Button
              variant={value === 100 ? "contained" : "outlined"}
              onClick={() => onSet(100)}
            >
              {t("100 %")}
            </Button>
          </ButtonGroup>
        </>
      );
    case "UnifiPoEPowerCycle":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => onSet(1)}
          >
            {t("power cycle")}
          </Button>
        </>
      );
    case "Range":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup size="large" color="primary">
            <Button
              variant="contained"
              onClick={() => {
                setNewValue(
                  (typeof newValue === "number" ? newValue : 0.0) - 0.1,
                );
                onSet((typeof newValue === "number" ? newValue : 0.0) - 0.1);
              }}
            >
              -
            </Button>
            <Button disabled style={{ color: "white" }}>
              {`${newValue} ${unit}`}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setNewValue(
                  (typeof newValue === "number" ? newValue : 0.0) + 0.1,
                );
                onSet((typeof newValue === "number" ? newValue : 0.0) + 0.1);
              }}
            >
              +
            </Button>
          </ButtonGroup>
        </>
      );
    case "RadarSensitivity":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup size="large" color="primary">
            <Button
              variant="contained"
              onClick={() => {
                setNewValue(
                  (typeof newValue === "number" ? newValue : 0.0) - 1,
                );
                onSet((typeof newValue === "number" ? newValue : 0.0) - 1);
              }}
            >
              -
            </Button>
            <Button disabled style={{ color: "white" }}>
              {`${newValue} ${unit}`}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setNewValue(
                  (typeof newValue === "number" ? newValue : 0.0) + 1,
                );
                onSet((typeof newValue === "number" ? newValue : 0.0) + 1);
              }}
            >
              +
            </Button>
          </ButtonGroup>
        </>
      );
    case "Delay":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <ButtonGroup size="large" color="primary">
            <Button
              variant="contained"
              onClick={() => {
                setNewValue(
                  (typeof newValue === "number" ? newValue : 0.0) - 5,
                );
                onSet((typeof newValue === "number" ? newValue : 0.0) - 5);
              }}
            >
              -
            </Button>
            <Button disabled style={{ color: "white" }}>
              {`${newValue} ${unit}`}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setNewValue(
                  (typeof newValue === "number" ? newValue : 0.0) + 5,
                );
                onSet((typeof newValue === "number" ? newValue : 0.0) + 5);
              }}
            >
              +
            </Button>
          </ButtonGroup>
        </>
      );
    case "ImageCaptureInterval":
      return (
        <>
          {name && <Typography variant="subtitle2">{name}</Typography>}
          <TraitIcon traitClass={traitClass} unit={unit} value={newValue} />
          <Slider
            className={styles.slider}
            value={typeof newValue === "number" ? newValue : 0.0}
            min={10}
            max={3600}
            step={5}
            valueLabelDisplay="auto"
            valueLabelFormat={(v: number) => (
              <TraitValueText traitClass={traitClass} value={v} unit={unit} />
            )}
            onChange={(event, sliderValue) =>
              setNewValue(sliderValue as number)
            }
            onChangeCommitted={(event, sliderValue) => {
              onSet(sliderValue as number);
            }}
          />
        </>
      );
    default:
      return <></>;
  }
});

const TraitPopper: FC<ITraitPopper> = observer((props) => {
  const styles = useStyles();

  return (
    <Popper
      id={props.id}
      open={props.open}
      anchorEl={props.anchor}
      placement="top"
      modifiers={[
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            rootBoundary: "document",
            padding: 8,
          },
        },
      ]}
    >
      <ClickAwayListener onClickAway={() => props.setOpen(false)}>
        <Box className={styles.popperBox}>
          <TraitPopperContent
            name={props.name}
            traitClass={props.traitClass}
            value={props.value}
            unit={props.unit}
            onSet={props.onSet}
          />
        </Box>
      </ClickAwayListener>
    </Popper>
  );
});

export default TraitPopper;
