import { DateTime } from "luxon";
import getAuthenticatedInstance from "./authenticated";

export type TLogLevel =
  | "CRITICAL"
  | "ERROR"
  | "WARNING"
  | "INFO"
  | "DEBUG"
  | "NOTSET";

export interface ILogMessage {
  time: string;
  logger: string;
  level: TLogLevel;
  level_number: number;
  message: string;
  exception: boolean;
  exception_type: string | null;
  traceback: string | null;
  tags: {
    [key: string]: string;
  };
}

export interface IFetchLogs {
  uuid: string;
  start: DateTime;
  end: DateTime;
  level: TLogLevel[];
}

export const fetchLogsForBuilding = async (
  query: IFetchLogs,
): Promise<ILogMessage[]> => {
  const response = await getAuthenticatedInstance().get(
    `/building/${query.uuid}/log/`,
    {
      params: {
        start: query.start.toISO(),
        end: query.end.toISO(),
        level: query.level.join(","),
      },
    },
  );
  return response.data as ILogMessage[];
};
