import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Card,
  CardContent,
  Container,
  Fab,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { runInAction } from "mobx";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoute } from "wouter";
import AppBar from "../components/AppBar";
import DeleteFab from "../components/DeleteFab";
import Group from "../components/Group";
import SmallIconButton from "../components/SmallIconButton";
import TraitGroupEdit, { ITraitGroupEdit } from "../components/TraitGroupEdit";
import IGroup, { ITraitGroup, deleteGroup, saveGroup } from "../services/Group";
import groupStore from "../stores/GroupStore";
import traitStore from "../stores/TraitStore";
import ErrorScreen from "./ErrorScreen";

const GroupScreen = () => {
  const { t } = useTranslation();
  const [, params] = useRoute("/building/:building_id/group/:uuid?/");
  const building: string =
    params && params.building_id ? params.building_id : "";
  const uuid: string = params && params.uuid ? params.uuid : "";
  const [group, setGroup] = useState<IGroup>(
    groupStore.find(uuid) ||
      ({
        uuid: "",
        building_id: building,
        name: "",
        traitgroups: [] as ITraitGroup[],
      } as IGroup),
  );
  const [traitGroups, setTraitGroups] = useState<ITraitGroupEdit[]>(
    (group.traitgroups || []).map((traitGroup) => {
      const traitUUIDs = traitGroup.traits;
      const traitClass =
        traitStore
          .filter({ building_id: building })
          .filter((trait) => traitUUIDs.indexOf(trait.uuid) !== -1)
          .pop()?.trait_class || "Temperature";

      return {
        isNew: false,
        uuid: traitGroup.uuid,
        building: building,
        name: traitGroup.name,
        group_as_one: traitGroup.group_as_one,
        traitClass: traitClass,
        traits: traitGroup.traits,
      } as ITraitGroupEdit;
    }),
  );

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (group.name.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [group]);

  const onSave = () => {
    runInAction(() => {
      group.traitgroups = traitGroups.map(
        (traitGroup) =>
          ({
            name: traitGroup.name,
            group_as_one: traitGroup.group_as_one,
            traits: traitGroup.traits,
          }) as ITraitGroup,
      );
    });
    saveGroup(group).then(() => window.history.back());
  };

  const onTraitGroupChange = (value: ITraitGroupEdit) => {
    setTraitGroups(
      traitGroups.map((traitGroup) =>
        traitGroup.uuid === value.uuid ? value : traitGroup,
      ),
    );
  };

  const onTraitGroupDelete = (isNew: boolean, uuidToDelete: string) => {
    setTraitGroups(
      traitGroups.filter((traitGroup) => traitGroup.uuid !== uuidToDelete),
    );
  };

  return (
    <Box>
      <AppBar
        title={group.name || t("Add Group")}
        withBack
        right={
          isValid && (
            <SmallIconButton
              variant="contained"
              color="secondary"
              text={t("Save")}
              startIcon={<SaveIcon />}
              onClick={onSave}
            />
          )
        }
      />
      <ErrorScreen>
        <Container maxWidth="xs" sx={{ marginTop: 2 }}>
          <Card>
            <CardContent>
              <Stack spacing={2} marginBottom={-1}>
                <TextField
                  variant="outlined"
                  type="text"
                  required
                  label={t("Name")}
                  defaultValue={group.name}
                  onChange={(event) =>
                    setGroup({ ...group, name: event.target.value })
                  }
                />
              </Stack>
            </CardContent>
          </Card>
        </Container>
        <Container maxWidth="xs" sx={{ marginTop: 2 }}>
          <Group
            uuid_or_group={{ ...group, traitgroups: traitGroups }}
            hideEditButton={true}
          />
        </Container>
        <Container maxWidth="xl" sx={{ marginTop: 2 }}>
          <Grid container spacing={2}>
            {traitGroups.map((traitGroup) => (
              <Grid
                key={traitGroup.uuid}
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
              >
                <TraitGroupEdit
                  isNew={traitGroup.isNew}
                  uuid={traitGroup.uuid}
                  building={building}
                  traitClass={traitGroup.traitClass}
                  name={traitGroup.name}
                  group_as_one={traitGroup.group_as_one}
                  traits={traitGroup.traits}
                  onChange={onTraitGroupChange}
                  onDelete={onTraitGroupDelete}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
        <Fab
          color="secondary"
          onClick={() => {
            setTraitGroups([
              ...traitGroups,
              {
                isNew: true,
                uuid: nanoid(),
                building: building,
                name: "",
                group_as_one: true,
                traits: [],
              } as ITraitGroupEdit,
            ]);
          }}
          sx={{
            zIndex: 100000,
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
        >
          <AddIcon />
        </Fab>
        {uuid && (
          <DeleteFab
            confirmText={t('Do you really want to delete group "{{name}}"?', {
              name: group.name,
            })}
            onDelete={() => {
              deleteGroup(uuid).then(() => window.history.back());
            }}
          />
        )}
      </ErrorScreen>
    </Box>
  );
};

export default GroupScreen;
