import { Container, Stack, Typography } from "@mui/material";
import React, { ErrorInfo, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import Logo from "../components/Logo";

export interface IRaspautomationError extends WithTranslation {
  children: ReactNode;
}

export interface IErrorState {
  hasError: boolean;
  errorType: null | "network";
}

class RaspautomationError extends React.Component<
  IRaspautomationError,
  IErrorState
> {
  constructor(props: IRaspautomationError) {
    super(props);
    this.state = {
      hasError: false,
      errorType: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    let errorType = "";

    switch (error.name) {
      case "ChunkLoadError":
        errorType = "network";
        break;
      default:
        break;
    }

    return {
      hasError: true,
      errorType: errorType,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error);
    console.log(error.name);
    console.log(errorInfo);
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <Container maxWidth="xs">
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            spacing={1}
            sx={{
              paddingTop: "10%",
            }}
          >
            <Logo />
            <Typography>{t("Something went wrong!")}</Typography>
            {
              <Typography>
                {this.state.errorType === "network"
                  ? t(
                      "Page loading failed. Please check your connectivity and reload the page.",
                    )
                  : t(
                      "Perhaps try reloading the page, going back or just deal with it.",
                    )}
              </Typography>
            }
          </Stack>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(RaspautomationError);
