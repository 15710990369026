import axios from "axios";
import getEnvironmentConfig from "../environment/config";
import { logger } from "../utils";
import getAuthenticatedInstance from "./authenticated";

export interface ILoginResponse {
  key: string;
}

export interface IUserResponse {
  pk: number;
  email: string;
  first_name: string;
  last_name: string;
}

export const login = async (
  email: string,
  password: string,
): Promise<ILoginResponse> => {
  try {
    const response = await axios
      .create({
        baseURL: getEnvironmentConfig().backend,
        timeout: 5000,
      })
      .post("/auth/login/", {
        email: email,
        password: password,
      });
    return response.data as ILoginResponse;
  } catch (err) {
    logger("Error logging in!", err);
  }
  return { key: "" } as ILoginResponse;
};

export const fetchUserDetails = async (): Promise<IUserResponse> => {
  try {
    const response = await getAuthenticatedInstance().get("/auth/user/");
    return response.data as IUserResponse;
  } catch (err) {
    logger("Error fetching user details!", err);
  }
  return {
    pk: 0,
    email: "",
    first_name: "",
    last_name: "",
  } as IUserResponse;
};
