import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import cronstrue from "cronstrue";
import "cronstrue/locales/fi";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import coreStore from "../stores/CoreStore";

export interface ITimeSelector {
  label: string;
  values: string;
  // defaultValue?: string;
  choices: string[];
  // eslint-disable-next-line react/require-default-props
  choiceTitles?: { [key: string]: string };
  onUpdate: (value: string, commit: boolean) => void;
}

export interface ICronData {
  minute: string;
  hour: string;
  dayOfMonth: string;
  month: string;
  dayOfWeek: string;
}

export interface ICronFormButtons {
  isAllNumeric: boolean;
}

export interface ICronInput {
  cronString: string;
  onChange: (value: string, commit: boolean) => void;
}

export const joinCronData = (cronData: ICronData): string => {
  return `${cronData.minute} ${cronData.hour} ${cronData.dayOfMonth} ${cronData.month} ${cronData.dayOfWeek}`.trim();
};

export const splitCronString = (cronString: string): ICronData => {
  const [minute, hour, dayOfMonth, month, dayOfWeek] = cronString.split(" ");
  const cronData: ICronData = {
    minute: minute,
    hour: hour,
    dayOfMonth: dayOfMonth,
    month: month,
    dayOfWeek: dayOfWeek,
  };
  return cronData;
};

const TimeSelector: FC<ITimeSelector> = (props: ITimeSelector) => {
  const { t } = useTranslation();
  const { label, values, choices, choiceTitles, onUpdate } = props;
  const [selectedValues, setSelectedValues] = useState<string[]>(
    values.split(",").filter((x) => x.length > 0),
  );

  const onFormChange = (event: SelectChangeEvent<string[]>) => {
    let selected = (
      typeof event.target.value === "string"
        ? (event.target.value as string).split(",")
        : event.target.value
    ).filter((x) => x !== "*");
    if (selected.indexOf("clear") !== -1) {
      selected = [];
    }
    if (selected.length === 0) {
      selected = ["*"];
    }
    selected.sort((a, b) => (parseInt(a, 10) < parseInt(b, 10) ? -1 : 1));
    setSelectedValues(selected);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        multiple
        value={selectedValues}
        renderValue={(selected) => {
          if (choiceTitles) {
            return selected.map((x) => choiceTitles[x]).join(", ") || "*";
          }
          return selected.join(", ") || "*";
        }}
        onChange={onFormChange}
        onClose={() => {
          onUpdate(selectedValues.join(","), true);
        }}
      >
        <MenuItem value="clear">
          <span style={{ fontWeight: "bold" }}>{t("Clear selections")}</span>
        </MenuItem>
        {choices.map((x) => (
          <MenuItem key={`choice-${x}`} value={x}>
            <Checkbox
              color="secondary"
              checked={selectedValues.indexOf(x) > -1}
            />
            {choiceTitles ? choiceTitles[x] : x}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CronFormInputs: FC<ICronInput> = (props: ICronInput) => {
  const { t } = useTranslation();
  const { cronString, onChange } = props;
  const cronData = splitCronString(cronString);

  const updateCronData = (
    partialCronData: Partial<ICronData>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    commit: boolean,
  ) => {
    onChange(
      joinCronData({
        ...cronData,
        ...partialCronData,
      }),
      true,
    );
  };

  return (
    <>
      <TimeSelector
        label={t("Minutes")}
        values={cronData.minute}
        choices={Array.from({ length: 60 }, (x, i) => i.toString())}
        onUpdate={(value, commit) => updateCronData({ minute: value }, commit)}
      />
      <TimeSelector
        label={t("Hours")}
        values={cronData.hour}
        choices={Array.from({ length: 24 }, (x, i) => i.toString())}
        onUpdate={(value, commit) => updateCronData({ hour: value }, commit)}
      />
      <TimeSelector
        label={t("Months")}
        values={cronData.month}
        choices={Array.from({ length: 12 }, (x, i) => (i + 1).toString())}
        choiceTitles={{
          "1": t("January"),
          "2": t("February"),
          "3": t("March"),
          "4": t("April"),
          "5": t("May"),
          "6": t("June"),
          "7": t("July"),
          "8": t("August"),
          "9": t("September"),
          "10": t("October"),
          "11": t("November"),
          "12": t("December"),
        }}
        onUpdate={(value, commit) => updateCronData({ month: value }, commit)}
      />
      <TimeSelector
        label={t("Days of month")}
        values={cronData.dayOfMonth}
        choices={Array.from({ length: 31 }, (x, i) => (i + 1).toString())}
        onUpdate={(value, commit) =>
          updateCronData({ dayOfMonth: value }, commit)
        }
      />
      <TimeSelector
        label={t("Days of week")}
        values={cronData.dayOfWeek}
        choices={Array.from({ length: 7 }, (x, i) => i.toString())}
        choiceTitles={{
          "0": t("Sunday"),
          "1": t("Monday"),
          "2": t("Tuesday"),
          "3": t("Wednesday"),
          "4": t("Thursday"),
          "5": t("Friday"),
          "6": t("Saturday"),
        }}
        onUpdate={(value, commit) =>
          updateCronData({ dayOfWeek: value }, commit)
        }
      />
    </>
  );
};

const CronTextInput: FC<ICronInput> = (props: ICronInput) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { cronString, onChange } = props;

  return (
    <Stack direction="column">
      <TextField
        label={t("Cron-style string")}
        value={cronString}
        onChange={(event) => onChange(event.target.value, false)}
        onBlur={(event) => onChange(event.target.value, true)}
      />
      <Alert
        severity="success"
        icon={false}
        sx={{
          textAlign: "center",
          "& a": {
            color: theme.palette.secondary.dark,
          },
        }}
      >
        <Trans>
          See for example{" "}
          <a href="https://crontab.guru" target="_blank" rel="noreferrer">
            https://crontab.guru
          </a>{" "}
          for help to design your cron-rule.
        </Trans>
      </Alert>
    </Stack>
  );
};

const CronFormButtons: FC<ICronFormButtons> = (props: ICronFormButtons) => {
  const { t } = useTranslation();
  const { isAllNumeric } = props;
  const useForm = coreStore.preferCronForm && isAllNumeric;

  return (
    <ButtonGroup>
      <Tooltip
        title={
          !isAllNumeric
            ? t(
                "Form input is disabled, as not all values are simple numbers. Change input as text to re-enable.",
              )
            : t("Use form to set times")
        }
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Button
            variant={useForm ? "contained" : "outlined"}
            onClick={() => {
              coreStore.setPreferCronFrom(true);
            }}
            sx={{
              color: "white",
            }}
            disabled={!isAllNumeric}
            fullWidth
          >
            {t("Use form")}
          </Button>
        </Box>
      </Tooltip>
      <Tooltip title={t("Use text-based input to set cron-string")}>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Button
            variant={useForm ? "outlined" : "contained"}
            onClick={() => {
              coreStore.setPreferCronFrom(false);
            }}
            sx={{
              color: "white",
            }}
            fullWidth
          >
            {t("Use text input")}
          </Button>
        </Box>
      </Tooltip>
    </ButtonGroup>
  );
};

const CronForm: FC<ICronInput> = observer((props: ICronInput) => {
  const { i18n } = useTranslation();
  const { cronString, onChange } = props;
  const { preferCronForm } = coreStore;
  const [isValid, setIsValid] = useState<boolean>(false);
  const [explanation, setExplanation] = useState<string>("");

  useEffect(() => {
    try {
      const numberOfParts = cronString.split(" ").length;
      if (numberOfParts !== 5) {
        throw `Error: Expression has over ${numberOfParts} parts. 5 parts are required.`;
      }
      setExplanation(cronstrue.toString(cronString, { locale: i18n.language }));
      setIsValid(true);
    } catch (err) {
      setExplanation((err as string).toString());
      setIsValid(false);
    }
  }, [cronString]);

  const isAllNumeric = !isNaN(
    Number(
      cronString.replaceAll(" ", "").replaceAll(",", "").replaceAll("*", ""),
    ),
  );
  const useForm = preferCronForm && isAllNumeric;

  const onUpdate = (value: string, commit: boolean) => {
    if (isValid || !commit) {
      onChange(value, commit);
    }
  };

  return (
    <>
      <Alert
        sx={{ textAlign: "center" }}
        icon={false}
        severity={isValid ? "success" : "error"}
      >
        {explanation}
      </Alert>
      <CronFormButtons isAllNumeric={isAllNumeric} />
      {useForm ? (
        <CronFormInputs cronString={cronString} onChange={onUpdate} />
      ) : (
        <CronTextInput cronString={cronString} onChange={onUpdate} />
      )}
    </>
  );
});

export default CronForm;
