/* eslint-disable no-nested-ternary */
import CachedIcon from "@mui/icons-material/Cached";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoute } from "wouter";
import AppBar from "../components/AppBar";
import DeleteFab from "../components/DeleteFab";
import Device, { DeviceType } from "../components/Device";
import SmallIconButton from "../components/SmallIconButton";
import Timestamp from "../components/Timestamp";
import TranslatedTrait from "../components/TranslatedTrait";
import GoogleHomeTraitSettingsCard from "../components/plugin/googlehome/GoogleHomeTraitSettingsCard";
import IDevice, {
  checkDeviceUpdate,
  deleteDevice,
  discoverDeviceTraits,
  refreshDeviceTraits,
  saveDevice,
  selectableDeviceTypes,
  startDeviceUpdate,
  TDeviceType,
} from "../services/Device";
import ITrait, {
  deleteTrait,
  ITraitSettings,
  patchTrait,
} from "../services/Trait";
import coreStore from "../stores/CoreStore";
import deviceStore from "../stores/DeviceStore";
import roomStore from "../stores/RoomStore";
import traitStore from "../stores/TraitStore";
import ErrorScreen from "./ErrorScreen";

const DeviceScreen = () => {
  const { t } = useTranslation();
  const [, params] = useRoute("/building/:building_id/device/:uuid/");
  const building: string =
    params && params.building_id ? params.building_id : "";
  const uuid: string = params && params.uuid ? params.uuid : "";
  const mobxDevice = deviceStore.find(uuid);
  const [device, setDevice] = useState<IDevice | undefined>(mobxDevice);
  const [room, setRoom] = useState<string | null>(device?.room_id || null);
  const rooms = [null, ...roomStore.filter({ building_id: building })];
  const [deviceType, setDeviceType] = useState<TDeviceType>(
    device?.type || null,
  );
  const [updateButtonClicked, setUpdateButtonClicked] =
    useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(device?.disabled || false);
  const [traits, setTraits] = useState<ITrait[]>(
    traitStore.filter({ device_id: uuid }),
  );
  const [changedTraits, setChangedTraits] = useState<string[]>([]);
  const [hasChanged, setHasChanged] = useState(false);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (device === undefined) {
      setIsValid(false);
      return;
    }
    if (device.name.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [device]);

  if (device === undefined) {
    return <></>;
  }

  useEffect(() => {
    if (!device || !mobxDevice) {
      return;
    }
    setHasChanged(
      device.name !== mobxDevice.name ||
        (room || null) !== device.room_id ||
        (deviceType || null) !== device.type ||
        disabled !== device.disabled,
    );
  }, [mobxDevice, device, room, deviceType, disabled]);

  const onSave = () => {
    const updatedDevice = {
      ...device,
      room_id: room || null,
      type: deviceType || null,
      disabled: disabled,
    };
    saveDevice(updatedDevice).then(() => {
      setDevice(updatedDevice);
    });
  };

  const updateTrait = (trait_uuid: string, options: ITraitSettings) => {
    setTraits(
      traits.map((trait) => {
        if (trait.uuid !== trait_uuid) {
          return trait;
        }
        setChangedTraits([...changedTraits, trait.uuid]);
        return {
          ...trait,
          ...options,
        };
      }),
    );
  };

  const onDeleteTrait = (trait_uuid: string) => {
    const trait = traitStore.find(trait_uuid);
    if (!trait) {
      return false;
    }
    if (
      confirm(
        t('Do you really want to delete trait "{{ name }}"?', {
          name: traitStore.getTraitText(trait),
        }),
      )
    ) {
      deleteTrait(trait).then(() => {
        setTraits(traits.filter((x) => x.uuid !== trait_uuid));
      });
    }
  };

  const traitIsValid = (trait_uuid: string) => {
    const trait = traits.find((x) => x.uuid === trait_uuid);
    if (!trait) {
      return false;
    }
    return isFinite(trait.multiplier);
  };

  const saveTrait = (trait_uuid: string) => {
    const trait = traits.find((x) => x.uuid === trait_uuid);
    if (!trait) {
      return;
    }
    patchTrait(trait).then(() => {
      setChangedTraits(changedTraits.filter((x) => x !== trait_uuid));
    });
  };

  useEffect(() => {
    setUpdateButtonClicked(false);
  }, [mobxDevice?.update_status]);

  return (
    <Box>
      <AppBar
        title={device.name}
        withBack
        right={
          isValid &&
          hasChanged && (
            <SmallIconButton
              variant="contained"
              color="secondary"
              text={t("Save")}
              startIcon={<SaveIcon />}
              onClick={onSave}
            />
          )
        }
      />
      <ErrorScreen>
        <Container maxWidth="xl" sx={{ marginTop: 2 }}>
          <Grid container spacing={2} justifyContent="center">
            <Device {...device} hideEditButton={true} />
            <Grid item xs={12} xl={3}>
              <Card>
                <CardHeader title={t("Device settings")} />
                <CardContent>
                  <Stack spacing={2} marginTop={-1}>
                    <TextField
                      variant="outlined"
                      type="text"
                      label={t("Name")}
                      value={device.name}
                      onChange={(event) =>
                        setDevice({ ...device, name: event.target.value })
                      }
                    />
                    {device.device_class !== "Device" && (
                      <TextField
                        variant="outlined"
                        type="text"
                        disabled
                        label={t("Device class")}
                        value={device.device_class}
                      />
                    )}
                    {device.alternative_id && (
                      <TextField
                        variant="outlined"
                        type="text"
                        disabled
                        label={t("Alternative ID")}
                        value={device.alternative_id}
                      />
                    )}
                    <FormControl fullWidth>
                      <InputLabel>{t("Room")}</InputLabel>
                      <Select
                        label={t("Room")}
                        value={room || ""}
                        onChange={(event) => {
                          const { value } = event.target;
                          setRoom(value?.length !== 0 ? value : null);
                        }}
                      >
                        {rooms.map((x) => (
                          <MenuItem
                            key={x?.uuid || `room-${device.uuid}-null`}
                            value={x?.uuid || ""}
                          >
                            {x?.name || t("(not selected)")}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel>{t("Device type")}</InputLabel>
                      <Select
                        label={t("Device type")}
                        value={deviceType || ""}
                        onChange={(event) => {
                          const { value } = event.target;
                          setDeviceType(
                            value?.length !== 0 ? (value as TDeviceType) : null,
                          );
                        }}
                      >
                        {selectableDeviceTypes.map((x) => (
                          <MenuItem
                            key={x || `devicetype-${device.uuid}-null`}
                            value={x || ""}
                          >
                            <DeviceType type={x} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormGroup>
                      <FormControlLabel
                        label={t("Disabled")}
                        control={
                          <Checkbox
                            checked={disabled}
                            onChange={(event) =>
                              setDisabled(event.target.checked)
                            }
                          />
                        }
                      />
                    </FormGroup>
                    {mobxDevice !== undefined &&
                      mobxDevice.update_status !== undefined && (
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={
                            mobxDevice.update_status > 1 || updateButtonClicked
                          }
                          startIcon={
                            mobxDevice.update_status > 0 && <PriorityHighIcon />
                          }
                          onClick={() => {
                            setUpdateButtonClicked(true);
                            if (mobxDevice.update_status === 1) {
                              startDeviceUpdate(mobxDevice);
                            } else if (
                              device.update_status === 0 ||
                              device.update_status === 3
                            ) {
                              checkDeviceUpdate(mobxDevice);
                            }
                          }}
                        >
                          {mobxDevice.update_status === 1
                            ? t("Update available")
                            : mobxDevice.update_status === 2
                              ? t("Update in progress {{progress}} %", {
                                  progress: mobxDevice.update_progress
                                    ? mobxDevice.update_progress
                                    : 0.0,
                                })
                              : mobxDevice.update_status === 3
                                ? t("Update finished")
                                : updateButtonClicked
                                  ? t("Checking for updates...")
                                  : t("Check for updates")}
                        </Button>
                      )}
                    {mobxDevice !== undefined &&
                      mobxDevice.device_class === "ZigbeeDevice" && (
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<QueryStatsIcon />}
                          onClick={() => {
                            discoverDeviceTraits(mobxDevice);
                          }}
                        >
                          {t("Discover traits")}
                        </Button>
                      )}
                    {mobxDevice !== undefined &&
                      mobxDevice.device_class === "ZigbeeDevice" && (
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<CachedIcon />}
                          onClick={() => {
                            refreshDeviceTraits(mobxDevice);
                          }}
                        >
                          {t("Refresh Trait values")}
                        </Button>
                      )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {traits.map((trait) => (
              <Grid item xs={12} xl={3} key={trait.uuid}>
                <Card>
                  <CardHeader
                    title={<TranslatedTrait traitClass={trait.trait_class} />}
                    subheader={
                      <Timestamp
                        timestamp={new Date(trait.last_updated).valueOf()}
                      />
                    }
                  />
                  <CardContent>
                    <Stack spacing={2} marginTop={-1}>
                      {trait.channel && (
                        <TextField
                          variant="outlined"
                          type="text"
                          label={t("Channel")}
                          value={trait.channel}
                          disabled
                        />
                      )}
                      <TextField
                        variant="outlined"
                        type="text"
                        label={t("Nickname")}
                        value={trait.nickname}
                        onChange={(event) =>
                          updateTrait(trait.uuid, {
                            nickname: event.target.value,
                          })
                        }
                      />
                      <TextField
                        variant="outlined"
                        type="number"
                        label={t("Multiplier")}
                        value={trait.multiplier}
                        onChange={(event) =>
                          updateTrait(trait.uuid, {
                            multiplier: parseFloat(event.target.value),
                          })
                        }
                      />
                      <FormGroup>
                        <FormControlLabel
                          label={t("Controllable")}
                          control={
                            <Checkbox
                              checked={trait.controllable}
                              onChange={(event) =>
                                updateTrait(trait.uuid, {
                                  controllable: event.target.checked,
                                })
                              }
                            />
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          label={t("Disabled")}
                          control={
                            <Checkbox
                              checked={trait.disabled}
                              onChange={(event) =>
                                updateTrait(trait.uuid, {
                                  disabled: event.target.checked,
                                })
                              }
                            />
                          }
                        />
                      </FormGroup>
                      {coreStore.isPluginEnabled("googlehome") &&
                        trait.controllable && (
                          <GoogleHomeTraitSettingsCard trait={trait} />
                        )}
                    </Stack>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "space-between" }}>
                    <SmallIconButton
                      variant="contained"
                      text={t("Delete")}
                      startIcon={<DeleteForeverIcon />}
                      onClick={() => onDeleteTrait(trait.uuid)}
                      sx={{
                        backgroundColor: "#b11212",
                      }}
                    />
                    {traitIsValid(trait.uuid) &&
                      changedTraits.includes(trait.uuid) && (
                        <SmallIconButton
                          variant="contained"
                          color="secondary"
                          text={t("Save")}
                          startIcon={<SaveIcon />}
                          onClick={() => saveTrait(trait.uuid)}
                        />
                      )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        {uuid && (
          <DeleteFab
            confirmText={t('Do you really want to delete device "{{name}}"?', {
              name: device.name,
            })}
            onDelete={() => {
              deleteDevice(uuid).then(() => window.history.back());
            }}
          />
        )}
      </ErrorScreen>
    </Box>
  );
};

export default DeviceScreen;
