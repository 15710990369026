import getAuthenticatedInstance from "./authenticated";

export const getPageCursor = (url: string): string | null =>
  new URL(url).searchParams.get("cursor");

export interface IPagedResponse<T> {
  next: string | null;
  previous: string | null;
  results: Array<T>;
}

export async function fetchSingle<T>(
  endpoint: string,
  uuid: string,
): Promise<T> {
  const response = await getAuthenticatedInstance().get(
    `/${endpoint}/${uuid}/`,
  );
  return response.data as T;
}

async function fetchPage<T>(
  endpoint: string,
  filters: { [key: string]: string } = {},
  cursor?: string | null,
): Promise<IPagedResponse<T>> {
  if (cursor) {
    // eslint-disable-next-line no-param-reassign
    filters.cursor = cursor;
  }
  const searchParams: string = new URLSearchParams(filters).toString();
  const response = await getAuthenticatedInstance().get(
    searchParams ? `/${endpoint}/?${searchParams}` : `/${endpoint}/`,
  );
  return response.data as IPagedResponse<T>;
}

export async function* fetchAllPages<T>(
  endpoint: string,
  filters: { [key: string]: string } = {},
): AsyncGenerator<T, void, void> {
  let next;
  let cursor;

  while (next !== null) {
    if (next !== undefined) {
      cursor = getPageCursor(next);
    }
    // eslint-disable-next-line no-await-in-loop
    const response: IPagedResponse<T> = await fetchPage(
      endpoint,
      filters,
      cursor,
    );
    next = response.next;
    // eslint-disable-next-line no-restricted-syntax
    for (const instance of response.results) {
      yield instance as T;
    }
  }
}
