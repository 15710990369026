import axios, { AxiosInstance } from "axios";
import getEnvironmentConfig from "../environment/config";
import userStore from "../stores/UserStore";

const getAuthenticatedInstance = (): AxiosInstance =>
  axios.create({
    baseURL: getEnvironmentConfig().backend,
    timeout: 30000,
    headers: {
      Authorization: `Token ${userStore.token}`,
    },
  });

export default getAuthenticatedInstance;
