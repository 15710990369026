// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAUXaMMCjosgh13z-KZ4SOv1FLg5Kwxv7E",
  authDomain: "raspautomationv2.firebaseapp.com",
  databaseURL: "https://raspautomationv2.firebaseio.com",
  projectId: "raspautomationv2",
  storageBucket: "raspautomationv2.appspot.com",
  messagingSenderId: "922079344255",
  appId: "1:922079344255:web:478d728ab9f1e4fa8bf14a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
