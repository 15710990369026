import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoute } from "wouter";
import AppBar from "../components/AppBar";
import SmallIconButton from "../components/SmallIconButton";
import IGateway, { saveGateway } from "../services/Gateway";
import gatewayStore from "../stores/GatewayStore";
import ErrorScreen from "./ErrorScreen";

const GatewayScreen = observer(() => {
  const { t } = useTranslation();
  const [, params] = useRoute("/building/:building_id/gateway/:uuid/");
  const uuid: string = params && params.uuid ? params.uuid : "";
  const storeDiscovery: boolean | undefined =
    gatewayStore.find(uuid)?.discovery;
  const [gateway, setGateway] = useState<IGateway | undefined>(
    gatewayStore.find(uuid),
  );

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (gateway === undefined) {
      setIsValid(false);
      return;
    }
    if (gateway.name.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [gateway]);

  autorun(() => {
    if (gateway && storeDiscovery !== gateway.discovery) {
      setGateway({
        ...gateway,
        discovery: storeDiscovery || false,
      });
    }
  });

  if (gateway === undefined) {
    return <></>;
  }

  const onSave = () => {
    saveGateway({
      ...gateway,
    }).then(() => window.history.back());
  };

  return (
    <Box>
      <AppBar
        title={gateway.name}
        withBack
        right={
          isValid && (
            <SmallIconButton
              variant="contained"
              color="secondary"
              text={t("Save")}
              startIcon={<SaveIcon />}
              onClick={onSave}
            />
          )
        }
      />
      <ErrorScreen>
        <Container maxWidth="xl" sx={{ marginTop: 2 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} xl={3}>
              <Card>
                <CardHeader title={t("Gateway settings")} />
                <CardContent>
                  <Stack spacing={2} marginTop={-1}>
                    <TextField
                      variant="outlined"
                      type="text"
                      label={t("Name")}
                      value={gateway.name}
                      onChange={(event) =>
                        setGateway({ ...gateway, name: event.target.value })
                      }
                    />
                    {gateway.discovery ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<ClearIcon />}
                        onClick={() => gatewayStore.turnDiscoveryOff(gateway)}
                      >
                        {t("Stop discovery")}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={() => gatewayStore.turnDiscoveryOn(gateway)}
                      >
                        {t("Discover new devices")}
                      </Button>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </ErrorScreen>
    </Box>
  );
});

export default GatewayScreen;
