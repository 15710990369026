import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsGantt from "highcharts/highcharts-gantt";
import HighchartsSankey from "highcharts/modules/sankey";
import { DateTime } from "luxon";
import { prefix } from "prefix-si";
import { FC, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import getEnvironmentConfig from "../environment/config";
import {
  IMultipleTraitHistoryResponse,
  fetchMultipleTraitHistory,
} from "../services/Trait";
import traitStore from "../stores/TraitStore";
import { TChartType, TChartTypes, isSankey } from "../stores/UI/ChartUIStore";
import { formatDuration } from "../stores/utils";
import TranslatedTrait from "./TranslatedTrait";

HighchartsSankey(Highcharts);

export interface IChartProps {
  chart: TChartTypes;
  startTime: DateTime;
  endTime: DateTime;
  hideEditButton?: boolean;
}

export interface IBarLineChart {
  type: TChartType;
  startTime: DateTime;
  endTime: DateTime;
  history: IMultipleTraitHistoryResponse;
  translatedTrait: string;
  unit: string | null;
}

export interface IGanttChartPeriod {
  name: string;
  start: number;
  end: number;
  y: number;
}

export interface IGanttChartProps {
  type: TChartType;
  startTime: DateTime;
  endTime: DateTime;
  history: IMultipleTraitHistoryResponse;
  translatedTrait: string;
  unit: string | null;
}

export interface ISankeyChartProps {
  type: TChartType;
  startTime: DateTime;
  endTime: DateTime;
  history: IMultipleTraitHistoryResponse;
  translatedTrait: string;
  unit: string | null;
  grid: string;
  production: string[];
  export: string;
  traits: string[];
}

const useStyles = makeStyles({
  cardHeader: {
    paddingBottom: "5px",
  },
  footer: {
    marginRight: "auto",
  },
});

const BarLineChart: FC<IBarLineChart> = (props: IBarLineChart) => {
  const { type, startTime, endTime, history, translatedTrait, unit } = props;

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        animation: false,
        chart: {
          type: type,
          backgroundColor: "transparent",
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        time: {
          useUTC: false,
        },
        colors: [
          "#7cb5ec",
          "#90ed7d",
          "#f7a35c",
          "#8085e9",
          "#f15c80",
          "#e4d354",
          "#2b908f",
          "#f45b5b",
          "#91e8e1",
        ],
        legend: {
          itemStyle: {
            color: "white",
            fontWeight: "normal",
          },
          itemHiddenStyle: {
            color: "#666666",
          },
          itemHoverStyle: {
            color: "#000000",
          },
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
        },
        yAxis: {
          title: {
            text: `${translatedTrait} (${unit})`,
            style: {
              color: "white",
            },
          },
          labels: {
            style: {
              color: "white",
            },
          },
        },
        xAxis: {
          type: "datetime",
          min: startTime.valueOf(),
          max: endTime.valueOf(),
          labels: {
            style: {
              color: "white",
            },
          },
        },
        tooltip: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter(): any {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const seriesName = (this as any).series.name;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = (this as any).y.toFixed(1);
            const time = DateTime.fromMillis(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (this as any).x,
            ).toLocaleString(DateTime.DATETIME_SHORT);
            return `<strong>${seriesName}</strong><br>${time}: ${value} ${unit}`;
          },
        },
        series: history.series
          .sort((a, b) =>
            traitStore.getTraitText(a.uuid) > traitStore.getTraitText(b.uuid)
              ? -1
              : 1,
          )
          .map((series, seriesIndex) => ({
            name:
              type === "column"
                ? !unit
                  ? `${traitStore.getTraitText(series.uuid)} (${prefix(
                      series.points.reduce((a, b) => a + b.value, 0),
                      "",
                      { spacer: " " },
                    )})`
                  : `${traitStore.getTraitText(series.uuid)} (${prefix(
                      series.points.reduce((a, b) => a + b.value, 0),
                      unit,
                      { spacer: " " },
                    )})`
                : traitStore.getTraitText(series.uuid),
            legendIndex: -seriesIndex,
            data: series.points.map((point) => [
              DateTime.fromISO(point.time).valueOf(),
              point.value,
            ]),
          })),
      }}
    />
  );
};

const GanttChart: FC<IGanttChartProps> = (props: IGanttChartProps) => {
  const { startTime, endTime, history } = props;

  const series = history.series
    .sort((a, b) =>
      traitStore.getTraitText(a.uuid) > traitStore.getTraitText(b.uuid)
        ? -1
        : 1,
    )
    .map((series, seriesIndex) => {
      const data: IGanttChartPeriod[] = [];

      let active = false,
        previous_active = false,
        active_period: IGanttChartPeriod | undefined = undefined;
      for (let i = 0; i < series.points.length; i++) {
        const point = series.points[i];
        active = point.value > 0;
        if (active && !previous_active) {
          active_period = {
            name: traitStore.getTraitText(series.uuid),
            start: DateTime.fromISO(point.time).valueOf(),
            end: 0,
            y: seriesIndex,
          };
        } else if (!active && previous_active) {
          active_period = {
            ...(active_period as unknown as IGanttChartPeriod),
            end: DateTime.fromISO(point.time).valueOf(),
          };
          data.push(active_period);
        }
        previous_active = active;
      }

      if (
        previous_active &&
        active_period !== undefined &&
        !active_period.end
      ) {
        active_period = {
          ...active_period,
          end:
            endTime <= DateTime.now()
              ? endTime.valueOf()
              : DateTime.now().valueOf(),
        };
        data.push(active_period);
      }

      const totalTime = data.reduce(
        (prev, cur) => prev + (cur.end.valueOf() - cur.start.valueOf()),
        0,
      );

      return {
        name: traitStore.getTraitText(series.uuid),
        type: "gantt",
        totalTime: totalTime,
        colorByPoint: false,
        legendIndex: -seriesIndex,
        data: data,
      };
    });

  return (
    <HighchartsReact
      highcharts={HighchartsGantt}
      options={{
        animation: false,
        chart: {
          type: "gantt",
          backgroundColor: "transparent",
        },
        plotOptions: {
          gantt: {
            events: {
              legendItemClick: () => {
                return false;
              },
            },
          },
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        time: {
          useUTC: false,
        },
        colors: [
          "#7cb5ec",
          "#90ed7d",
          "#f7a35c",
          "#8085e9",
          "#f15c80",
          "#e4d354",
          "#2b908f",
          "#f45b5b",
          "#91e8e1",
        ],
        legend: {
          itemStyle: {
            color: "white",
            fontWeight: "normal",
          },
          itemHiddenStyle: {
            color: "#666666",
          },
          itemHoverStyle: {
            color: "#000000",
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          labelFormatter(): any {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return `${(this as any).userOptions.name} - ${
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              formatDuration((this as any).userOptions.totalTime)
            }`;
          },
        },
        // plotOptions: {
        //   line: {
        //     marker: {
        //       enabled: false,
        //     },
        //   },
        // },
        yAxis: {
          uniqueNames: true,
          title: "",
          min: -0.5,
          max: series.length,
          labels: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter(): any {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const series = (this as any).axis.series[(this as any).pos];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return series.name;
              } catch {
                return "";
              }
            },
            style: {
              color: "white",
            },
          },
          tickInterval: 1,
        },
        xAxis: {
          type: "datetime",
          min: startTime.valueOf(),
          max: endTime.valueOf(),
          labels: {
            style: {
              color: "white",
            },
          },
        },
        dataLabels: {
          format: "name",
        },
        tooltip: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter(): any {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const series = (this as any).series;
            const seriesName = series.name;
            const start = DateTime.fromMillis(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (this as any).x,
            );
            const end = DateTime.fromMillis(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (this as any).x2,
            );
            return `<strong>${seriesName}</strong><br>${start.toLocaleString(
              DateTime.TIME_24_SIMPLE,
            )} - ${end.toLocaleString(
              DateTime.TIME_24_SIMPLE,
            )}<br>${formatDuration(end.valueOf() - start.valueOf())}`;
          },
        },
        series: series,
      }}
    />
  );
};

const SankeyChart: FC<ISankeyChartProps> = (props: ISankeyChartProps) => {
  const { t } = useTranslation();
  const {
    history,
    traits,
    unit,
    grid,
    production,
    export: exportTrait,
  } = props;

  const cleanSeries = history.series.map((series) => ({
    uuid: series.uuid,
    total:
      series.points.reduce(
        (previous, current) => previous + current.value,
        0,
      ) || 0,
  }));

  const importEnergy =
    cleanSeries.find((series) => series.uuid === grid)?.total || 0;
  const exportEnergy =
    cleanSeries.find((series) => series.uuid === exportTrait)?.total || 0;
  const productionSeries = cleanSeries
    .filter((series) => production.includes(series.uuid))
    .filter((series) => series.total > 0);
  const consumptionSeries = cleanSeries
    .filter((series) => traits.includes(series.uuid))
    .filter((series) => series.total > 0);
  const totalProduction =
    productionSeries.reduce(
      (previous, current) => previous + current.total || 0,
      0,
    ) || 0;
  const totalConsumption = importEnergy + totalProduction - exportEnergy;
  const otherConsumption =
    totalConsumption -
    consumptionSeries.reduce((previous, series) => previous + series.total, 0);

  const data = [];
  if (productionSeries.length > 0) {
    data.push([t("Grid import"), t("Total consumption"), importEnergy]);
  }
  if (productionSeries.length > 1) {
    data.push(
      ...productionSeries.map((series) => [
        traitStore.getTraitText(series.uuid),
        t("Total production"),
        series.total,
      ]),
    );
    data.push([
      t("Total production"),
      t("Total consumption"),
      totalProduction - exportEnergy,
    ]);
    data.push([t("Total production"), t("Export"), exportEnergy]);
  } else if (productionSeries.length > 0) {
    const series = productionSeries[0];
    data.push([
      traitStore.getTraitText(series.uuid),
      t("Total consumption"),
      totalProduction - exportEnergy,
    ]);
    data.push([
      traitStore.getTraitText(series.uuid),
      t("Export"),
      exportEnergy,
    ]);
  }
  data.push(
    ...consumptionSeries.map((series) => [
      t("Total consumption"),
      traitStore.getTraitText(series.uuid),
      series.total,
    ]),
  );
  data.push([t("Total consumption"), t("Other consumption"), otherConsumption]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        animation: false,
        chart: {
          type: "sankey",
          backgroundColor: "transparent",
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        time: {
          useUTC: false,
        },
        colors: [
          "#7cb5ec",
          "#90ed7d",
          "#f7a35c",
          "#8085e9",
          "#f15c80",
          "#e4d354",
          "#2b908f",
          "#f45b5b",
          "#91e8e1",
        ],
        tooltip: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter(): any {
            const seriesName: string[] = [];
            const seriesExtra: string[] = [];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const point = (this as any).point;
            const value = point.weight || point.sum;
            if (point.isNode) {
              seriesName.push(point.name);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              point.linksTo.forEach((x: any) => {
                const percentage = ((x.weight || x.sum) / value) * 100;
                if (percentage < 100) {
                  seriesExtra.push(
                    `${x.from}: ${x.weight} ${unit} / ${percentage.toFixed(
                      1,
                    )} %`,
                  );
                }
              });
            } else {
              seriesName.push(point.from);
              seriesName.push(point.to);
              const percentage =
                ((value /
                  (point.fromNode.weight || point.fromNode.sum)) as number) *
                100;
              if (percentage < 100) {
                seriesExtra.push(`${percentage.toFixed(1)} %`);
              }
            }
            return `<strong>${seriesName.join(
              " 🡒 ",
            )}</strong><br>${value.toFixed(0)} ${unit} ${
              seriesExtra.length > 0 ? "<br />" : ""
            } ${seriesExtra.join("<br />")}`;
          },
        },
        series: [
          {
            nodeAlignment: "top",
            keys: ["from", "to", "weight"],
            data: data.filter((x) => (x[2] as number) > 0),
          },
        ],
      }}
    />
  );
};

const Chart: FC<IChartProps> = (props: IChartProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [, setLocation] = useLocation();
  const { chartRefreshInterval } = getEnvironmentConfig();
  const { chart, startTime, endTime, hideEditButton } = props;
  const [history, setHistory] = useState<IMultipleTraitHistoryResponse | null>(
    null,
  );
  const [translatedTrait, setTranslatedTrait] = useState<string>("");
  const [unit, setUnit] = useState<string>("");
  const fetchHistory = () => {
    let traitsToFetch = chart.traits;
    if (isSankey(chart)) {
      traitsToFetch = [
        ...traitsToFetch,
        chart.grid,
        chart.export,
        ...chart.production,
      ].filter((x) => x !== "");
    }
    if (traitsToFetch.length === 0) {
      return;
    }
    fetchMultipleTraitHistory({
      start_time: startTime.toISO() as string,
      end_time: endTime.toISO() as string,
      uuid: traitsToFetch,
      aggregate: chart.aggregate,
      window: chart.window,
    }).then((response) => {
      setHistory(response);
      if (response.series.length > 0) {
        setTranslatedTrait(
          ReactDOMServer.renderToString(
            <TranslatedTrait traitClass={response.series[0].printable_name} />,
          ),
        );
        setUnit(response.series[0].unit);
      }
    });
  };

  useEffect(() => {
    fetchHistory();
    const interval = setInterval(
      () => fetchHistory(),
      chartRefreshInterval * 60000,
    );
    return () => clearInterval(interval);
  }, [chart.traits, startTime, endTime]);

  if (!history) {
    return null;
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Card>
        {chart.name && (
          <CardHeader title={chart.name} className={classes.cardHeader} />
        )}
        <CardContent sx={{ paddingTop: 2, paddingLeft: 0, paddingRight: 0 }}>
          {(chart.type === "column" || chart.type === "line") && (
            <BarLineChart
              type={chart.type}
              startTime={startTime}
              endTime={endTime}
              history={history}
              translatedTrait={translatedTrait}
              unit={unit}
            />
          )}
          {chart.type === "gantt" && (
            <GanttChart
              type={chart.type}
              startTime={startTime}
              endTime={endTime}
              history={history}
              translatedTrait={translatedTrait}
              unit={unit}
            />
          )}
          {isSankey(chart) && (
            <SankeyChart
              type={chart.type}
              startTime={startTime}
              endTime={endTime}
              history={history}
              translatedTrait={translatedTrait}
              unit={unit}
              grid={chart.grid}
              production={chart.production}
              export={chart.export}
              traits={chart.traits}
            />
          )}
        </CardContent>
        {hideEditButton ? null : (
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<EditIcon />}
              onClick={() =>
                setLocation(`/building/${chart.building_id}/chart/${chart.id}/`)
              }
            >
              {t("Edit")}
            </Button>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

export default Chart;
