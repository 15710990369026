import IGateway, { saveGateway } from "../services/Gateway";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";

export interface IGatewayFilter extends IBuildingChildFilter {
  room_id?: string;
}

class GatewayStore extends BuildingChildPagedStore<
  IGateway,
  IBuildingChildFilter
> {
  constructor() {
    super("gateway");
    this.persist();
  }

  turnDiscoveryOn = async (gateway: IGateway): Promise<IGateway> => {
    return saveGateway({
      ...gateway,
      discovery: true,
    });
  };

  turnDiscoveryOff = async (gateway: IGateway): Promise<IGateway> => {
    return saveGateway({
      ...gateway,
      discovery: false,
    });
  };
}

const gatewayStore = new GatewayStore();
export default gatewayStore;
