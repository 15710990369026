import { Box } from "@mui/material";
import getEnvironmentConfig from "../environment/config";

const VersionInfo = () => {
  const { version } = getEnvironmentConfig();
  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "center",
        color: "#777",
        paddingTop: 5,
      }}
    >
      {version}
    </Box>
  );
};

export default VersionInfo;
