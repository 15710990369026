import getAuthenticatedInstance from "./authenticated";

export interface IPlugin {
  enabled: string[];
  available: string[];
}

export const getPlugins = async (): Promise<IPlugin> => {
  const response = await getAuthenticatedInstance().get("/plugin/");
  return response.data as IPlugin;
};
