import getAuthenticatedInstance from "./authenticated";
import { BuildingChildInstance } from "./base";

interface IGateway extends BuildingChildInstance {
  gateway_class: string;
  building_id: string;
  room_id: string | null;
  name: string;
  disabled: boolean;
  discovery: boolean;
}

export const saveGateway = async (gateway: IGateway): Promise<IGateway> => {
  const response = await getAuthenticatedInstance().patch(
    `/gateway/${gateway.uuid}/`,
    {
      name: gateway.name,
      disabled: gateway.disabled,
      discovery: gateway.discovery,
    },
  );
  return response.data as IGateway;
};

export default IGateway;
