/* eslint-disable */
// https://github.com/Shnoo/js-CIE-1931-rgb-color-converter
class ColorConverter {
  static getGamutRanges() {
    const gamutA = {
      red: [0.704, 0.296],
      green: [0.2151, 0.7106],
      blue: [0.138, 0.08],
    };

    const gamutB = {
      red: [0.675, 0.322],
      green: [0.409, 0.518],
      blue: [0.167, 0.04],
    };

    const gamutC = {
      red: [0.692, 0.308],
      green: [0.17, 0.7],
      blue: [0.153, 0.048],
    };

    const defaultGamut = {
      red: [1.0, 0],
      green: [0.0, 1.0],
      blue: [0.0, 0.0],
    };

    return {
      gamutA: gamutA,
      gamutB: gamutB,
      gamutC: gamutC,
      default: defaultGamut,
    };
  }

  static getLightColorGamutRange(modelId = null) {
    const ranges = ColorConverter.getGamutRanges();
    const { gamutA, gamutB, gamutC } = ranges.gamutA;

    const philipsModels = {
      LST001: gamutA,
      LLC010: gamutA,
      LLC011: gamutA,
      LLC012: gamutA,
      LLC006: gamutA,
      LLC005: gamutA,
      LLC007: gamutA,
      LLC014: gamutA,
      LLC013: gamutA,

      LCT001: gamutB,
      LCT007: gamutB,
      LCT002: gamutB,
      LCT003: gamutB,
      LLM001: gamutB,

      LCT010: gamutC,
      LCT014: gamutC,
      LCT015: gamutC,
      LCT016: gamutC,
      LCT011: gamutC,
      LLC020: gamutC,
      LST002: gamutC,
      LCT012: gamutC,
    };

    if (philipsModels[modelId]) {
      return philipsModels[modelId];
    }

    return ranges.default;
  }

  static rgbToXy(red, green, blue, modelId = null) {
    function getGammaCorrectedValue(value) {
      return value > 0.04045
        ? (value + 0.055) / (1.0 + 0.055) ** 2.4
        : value / 12.92;
    }

    const colorGamut = ColorConverter.getLightColorGamutRange(modelId);

    let r = parseFloat(red / 255);
    let g = parseFloat(green / 255);
    let b = parseFloat(blue / 255);

    r = getGammaCorrectedValue(r);
    g = getGammaCorrectedValue(g);
    b = getGammaCorrectedValue(b);

    const x = r * 0.649926 + g * 0.103455 + b * 0.197109;
    const y = r * 0.234327 + g * 0.743075 + b * 0.022598;
    const z = r * 0.0 + g * 0.053077 + b * 1.035763;

    let xy = {
      x: x / (x + y + z),
      y: y / (x + y + z),
    };

    if (!ColorConverter.xyIsInGamutRange(xy, colorGamut)) {
      xy = ColorConverter.getClosestColor(xy, colorGamut);
    }

    return xy;
  }

  static xyIsInGamutRange(xy, gamut) {
    const g = gamut || ColorConverter.getGamutRanges().gamutC;
    let XY = xy;
    if (Array.isArray(xy)) {
      XY = {
        x: XY[0],
        y: XY[1],
      };
    }

    const v0 = [g.blue[0] - g.red[0], g.blue[1] - g.red[1]];
    const v1 = [g.green[0] - g.red[0], g.green[1] - g.red[1]];
    const v2 = [XY.x - g.red[0], XY.y - g.red[1]];

    const dot00 = v0[0] * v0[0] + v0[1] * v0[1];
    const dot01 = v0[0] * v1[0] + v0[1] * v1[1];
    const dot02 = v0[0] * v2[0] + v0[1] * v2[1];
    const dot11 = v1[0] * v1[0] + v1[1] * v1[1];
    const dot12 = v1[0] * v2[0] + v1[1] * v2[1];

    const invDenom = 1 / (dot00 * dot11 - dot01 * dot01);

    const u = (dot11 * dot02 - dot01 * dot12) * invDenom;
    const v = (dot00 * dot12 - dot01 * dot02) * invDenom;

    return u >= 0 && v >= 0 && u + v < 1;
  }

  static getClosestColor(xy, gamut) {
    function getLineDistance(pointA, pointB) {
      return Math.hypot(pointB.x - pointA.x, pointB.y - pointA.y);
    }

    function getClosestPoint(XY, pointA, pointB) {
      const xy2a = [XY.x - pointA.x, XY.y - pointA.y];
      const a2b = [pointB.x - pointA.x, pointB.y - pointA.y];
      const a2bSqr = a2b[0] ** 2 + a2b[1] ** 2;
      const xy2aDotA2B = xy2a[0] * a2b[0] + xy2a[1] * a2b[1];
      const t = xy2aDotA2B / a2bSqr;

      return {
        x: pointA.x + a2b[0] * t,
        y: pointA.y + a2b[1] * t,
      };
    }

    const greenBlue = {
      a: {
        x: gamut.green[0],
        y: gamut.green[1],
      },
      b: {
        x: gamut.blue[0],
        y: gamut.blue[1],
      },
    };

    const greenRed = {
      a: {
        x: gamut.green[0],
        y: gamut.green[1],
      },
      b: {
        x: gamut.red[0],
        y: gamut.red[1],
      },
    };

    const blueRed = {
      a: {
        x: gamut.red[0],
        y: gamut.red[1],
      },
      b: {
        x: gamut.blue[0],
        y: gamut.blue[1],
      },
    };

    const closestColorPoints = {
      greenBlue: getClosestPoint(xy, greenBlue.a, greenBlue.b),
      greenRed: getClosestPoint(xy, greenRed.a, greenRed.b),
      blueRed: getClosestPoint(xy, blueRed.a, blueRed.b),
    };

    const distance = {
      greenBlue: getLineDistance(xy, closestColorPoints.greenBlue),
      greenRed: getLineDistance(xy, closestColorPoints.greenRed),
      blueRed: getLineDistance(xy, closestColorPoints.blueRed),
    };

    let closestDistance;
    let closestColor;
    // eslint-disable-line
    for (const i in distance) {
      if (distance.hasOwnProperty(i)) {
        if (!closestDistance) {
          closestDistance = distance[i];
          closestColor = i;
        }

        if (closestDistance > distance[i]) {
          closestDistance = distance[i];
          closestColor = i;
        }
      }
    }
    return closestColorPoints[closestColor];
  }

  static xyBriToRgb(x, y, bri) {
    function getReversedGammaCorrectedValue(value) {
      return value <= 0.0031308
        ? 12.92 * value
        : (1.0 + 0.055) * Math.pow(value, 1.0 / 2.4) - 0.055;
    }

    let z = 1.0 - x - y;
    let Y = bri / 255;
    let X = (Y / y) * x;
    let Z = (Y / y) * z;
    let r = X * 1.656492 - Y * 0.354851 - Z * 0.255038;
    let g = -X * 0.707196 + Y * 1.655397 + Z * 0.036152;
    let b = X * 0.051713 - Y * 0.121364 + Z * 1.01153;

    r = getReversedGammaCorrectedValue(r);
    g = getReversedGammaCorrectedValue(g);
    b = getReversedGammaCorrectedValue(b);

    // Bring all negative components to zero
    r = Math.max(r, 0);
    g = Math.max(g, 0);
    b = Math.max(b, 0);

    // If one component is greater than 1, weight components by that value
    let max = Math.max(r, g, b);
    if (max > 1) {
      r = r / max;
      g = g / max;
      b = b / max;
    }

    return {
      r: Math.floor(r * 255),
      g: Math.floor(g * 255),
      b: Math.floor(b * 255),
    };
  }
}

export default ColorConverter;
