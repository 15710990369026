import { action, autorun, makeObservable, observable, runInAction } from "mobx";
import mqtt, { IPublishPacket, MqttClient } from "mqtt";
import getEnvironmentConfig from "../environment/config";
import IBuilding from "../services/Building";
import IDevice from "../services/Device";
import IGateway from "../services/Gateway";
import IGroup from "../services/Group";
import IRoom from "../services/Room";
import IRule from "../services/Rule";
import IState from "../services/State";
import ITrait from "../services/Trait";
import { IGoogleHomeTraitSettings } from "../services/plugin/GoogleHome";
import { logger } from "../utils";
import buildingStore from "./BuildingStore";
import deviceStore from "./DeviceStore";
import gatewayStore from "./GatewayStore";
import groupStore from "./GroupStore";
import roomStore from "./RoomStore";
import ruleStore from "./RuleStore";
import stateStore from "./StateStore";
import traitStore from "./TraitStore";
import userStore from "./UserStore";
import googleHomeTraitSettingsStore from "./plugin/GoogleHome";

const SETTLE_TIMEOUT = 750;

export interface IMQTTStore {
  client: MqttClient | null;
  topics: string[];
  connected: boolean;
  settled: boolean;
  settleTimer?: ReturnType<typeof setTimeout>;
  settle: () => void;
  unsettle: () => void;
  setSettleTimer: () => void;
  clearSettleTimer: () => void;
  subscribe: (topic: string) => void;
  unsubscribe: (topic: string) => void;
  handleMessage: (topic: string, payload: Buffer, msg: IPublishPacket) => void;
  handleDelete: (messageType: string, uuid: string, rest: string[]) => void;
}

class MQTTStore implements IMQTTStore {
  client: MqttClient | null = null;
  connected = false;
  settled = false;
  settleTimer?: ReturnType<typeof setTimeout> = undefined;
  topics: string[] = [];

  constructor() {
    makeObservable(this, {
      client: observable,
      connected: observable,
      settled: observable,
      topics: observable,
      settle: action,
      unsettle: action,
      subscribe: action,
      unsubscribe: action,
      handleMessage: action,
    });

    autorun(() => {
      if (!this.connected) {
        logger("MQTTStore: MQTTClient disconnected!");
        this.unsettle();
      } else {
        logger("MQTTStore: MQTTClient connected!");
        this.topics.forEach((topic) => {
          this.client?.subscribe(topic);
        });
        this.unsettle();
      }
    });

    autorun(() => {
      if (!this.client) {
        runInAction(() => {
          this.topics = [];
          this.connected = false;
        });
      }
    });

    autorun(() => {
      const { token, isHydrated } = userStore;

      if (isHydrated && token !== null && token.length !== 0) {
        logger("MQTTStore: Initializing MQTTClient!");
        runInAction(() => {
          this.client = mqtt.connect(getEnvironmentConfig().mqtt, {
            clientId: `react-${(Math.random() * 1e12).toFixed()}`,
            username: token,
            password: token,
          });
          this.client.on("connect", () => {
            runInAction(() => {
              this.connected = true;
            });
          });
          this.client.on("message", (topic, payload, msg) =>
            setTimeout(() => this.handleMessage(topic, payload, msg), 10),
          );
        });
      } else if (this.client) {
        logger("MQTTStore: Disconnecting client!");
        this.client.end();
        runInAction(() => {
          this.client = null;
        });
      }
    });
  }

  settle = () => {
    logger("MQTTStore: MQTT settled...");
    runInAction(() => {
      this.settled = true;
    });
  };

  unsettle = () => {
    logger("MQTTStore: MQTT unsettled...");
    runInAction(() => {
      this.settled = false;
    });
    this.setSettleTimer();
  };

  setSettleTimer = () => {
    this.clearSettleTimer();
    this.settleTimer = setTimeout(() => {
      this.settle();
    }, SETTLE_TIMEOUT);
  };

  clearSettleTimer = () => {
    if (this.settleTimer) {
      clearTimeout(this.settleTimer);
    }
  };

  subscribe = (topic: string) => {
    if (this.topics.indexOf(topic) === -1) {
      logger(`MQTTStore: Subscribing to ${topic}`);
      this.unsettle();
      if (this.client && this.client.connected) {
        this.client.subscribe(topic);
      }
      runInAction(() => this.topics.push(topic));
    }
  };

  unsubscribe = (topic: string) => {
    if (this.topics.indexOf(topic) !== -1) {
      logger(`MQTTStore: Unsubscribing from ${topic}`);
      this.unsettle();
      if (this.client && this.client.connected) {
        this.client.unsubscribe(topic);
      }
      runInAction(() => {
        this.topics = this.topics.filter(
          (existing_topic) => topic !== existing_topic,
        );
      });
    }
  };

  handleMessage = (topic: string, payload: Buffer, msg: IPublishPacket) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, , building, messageType, targetUUID, ...rest] = topic.split("/");
    if (!this.settled && msg.retain) {
      this.setSettleTimer();
    }
    if (!payload.toString()) {
      this.handleDelete(messageType, targetUUID, rest);
      return;
    }
    const message = JSON.parse(payload.toString());
    logger("MQTTStore: Message received!");
    switch (messageType) {
      case undefined:
        buildingStore.update(message as IBuilding);
        break;
      case "room":
        roomStore.update(message as IRoom);
        break;
      case "device":
        deviceStore.update(message as IDevice);
        break;
      case "trait":
        traitStore.update(message as ITrait);
        break;
      case "group":
        groupStore.update(message as IGroup);
        break;
      case "rule":
        ruleStore.update(message as IRule);
        break;
      case "gateway":
        gatewayStore.update(message as IGateway);
        break;
      case "state":
        stateStore.update(message as IState);
        break;
      case "plugin":
        switch (targetUUID) {
          case "googlehome":
            if (rest[0] === "trait") {
              googleHomeTraitSettingsStore.update(
                message as IGoogleHomeTraitSettings,
              );
            }
            break;
          default:
            break;
        }
        break;
      default:
        logger(`MQTTStore: Message to unhandled topic ${topic}`);
        break;
    }
  };

  handleDelete = (messageType: string, uuid: string, rest: string[]) => {
    logger(`MQTTStore: Deleting ${uuid} from ${messageType}`);
    switch (messageType) {
      case "room":
        roomStore.delete(uuid);
        break;
      case "device":
        deviceStore.delete(uuid);
        break;
      case "trait":
        traitStore.delete(uuid);
        break;
      case "group":
        groupStore.delete(uuid);
        break;
      case "rule":
        ruleStore.delete(uuid);
        break;
      case "gateway":
        gatewayStore.delete(uuid);
        break;
      case "state":
        stateStore.delete(uuid);
        break;
      case "plugin":
        switch (uuid) {
          case "googlehome":
            if (rest[0] === "trait") {
              googleHomeTraitSettingsStore.delete(rest[1]);
            }
            break;
          default:
            break;
        }
        break;
      default:
        logger(`MQTTStore: Unable to handleDelete for ${messageType} ${uuid}`);
        break;
    }
  };
}

const mqttStore = new MQTTStore();
export default mqttStore;
