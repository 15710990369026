import { action, makeObservable, observable, runInAction } from "mobx";
import { TChartAggregate, TChartWindow } from "../../services/Trait";
import { IPersistedStore } from "../PersistedStore";
import { persist, typedKeys } from "../utils";

export type TChartType = "line" | "column" | "gantt" | "sankey";
export interface IChart {
  building_id: string;
  id: string;
  name: string;
  type: TChartType;
  aggregate: TChartAggregate;
  window: TChartWindow;
  traitClass: string;
  traits: string[];
}

export interface IGanttChart extends IChart {
  type: "gantt";
}

export interface ISankeyChart extends IChart {
  type: "sankey";
  grid: string;
  production: string[];
  export: string;
}

export type TChartTypes = IChart | IGanttChart | ISankeyChart;

export interface IChartStoreFilter {
  building_id?: string;
  type?: TChartType;
  // Not really a filter
  sorted?: boolean;
}

export interface IChartUIStore extends IPersistedStore {
  instances: TChartTypes[];
  sort: (a: TChartTypes, b: TChartTypes) => -1 | 1;
  filter: (filters: IChartStoreFilter) => TChartTypes[];
  find: (id: string) => TChartTypes | undefined;
  findIndex: (id: string) => number;
  update: (instance: TChartTypes) => [TChartTypes, boolean];
  setOrder: (ids: string[]) => void;
}

export const isSankey = (chart: TChartTypes): chart is ISankeyChart => {
  return chart.type === "sankey";
};

class ChartUIStore implements IChartUIStore {
  isHydrated = false;
  instances: IChart[] = [];

  constructor() {
    makeObservable(this, {
      isHydrated: observable,
      instances: observable,
      update: action,
      deleteChart: action,
      setOrder: action,
    });

    persist(this, "UI.chart", ["instances"], undefined, 1);
  }

  sort = (a: TChartTypes, b: TChartTypes) => {
    return chartUIStore.findIndex(a.id) < chartUIStore.findIndex(b.id) ? -1 : 1;
  };

  filter = (filters: IChartStoreFilter): TChartTypes[] => {
    const sorted = filters.sorted === true;
    const instances = this.instances.filter((instance) =>
      typedKeys(filters)
        .filter((key) => key !== "sorted")
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .every((key) => instance[key] === filters[key]),
    );
    if (sorted) {
      return instances.sort(this.sort);
    }
    return instances;
  };

  find = (id: string): TChartTypes | undefined =>
    this.instances.find((instance) => instance.id === id);

  findIndex = (id: string): number =>
    this.instances.findIndex((instance) => instance.id === id);

  update = (instance: TChartTypes): [TChartTypes, boolean] => {
    const instanceIndex = this.findIndex(instance.id);
    if (instanceIndex === -1) {
      // If instance wasn't found -> push to end
      runInAction(() => this.instances.push(instance as IChart));
      return [instance as TChartTypes, true];
    }
    // Otherwise update the instance
    runInAction(() => {
      this.instances[instanceIndex] = instance as
        | IChart
        | IGanttChart
        | ISankeyChart;
    });
    return [instance as TChartTypes, false];
  };

  deleteChart = (instance: TChartTypes) => {
    runInAction(() => {
      this.instances = this.instances.filter(
        (chart) => chart.id !== instance.id,
      );
    });
  };

  setOrder = (ids: string[]) => {
    runInAction(() => {
      this.instances.sort((a, b) =>
        ids.indexOf(a.id) < ids.indexOf(b.id) ? -1 : 1,
      );
    });
  };
}

const chartUIStore = new ChartUIStore();
export default chartUIStore;
