import { IconButton, Stack } from "@mui/material";
import userStore from "../stores/UserStore";

interface ILanguageFlag {
  code: "en" | "fi";
}

const LanguageFlag = (props: ILanguageFlag) => {
  const { code } = props;
  const flag = code === "en" ? "gb" : code;

  return (
    <IconButton size="large" onClick={() => userStore.setLanguage(code)}>
      <img
        loading="lazy"
        width="30"
        src={`https://flagcdn.com/w40/${flag.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w80/${flag.toLowerCase()}.png 2x`}
        alt=""
      />
    </IconButton>
  );
};

const LanguageSwitcher = (): JSX.Element => {
  return (
    <Stack direction="row" justifyContent="center">
      <LanguageFlag code="en" />
      <LanguageFlag code="fi" />
    </Stack>
  );
};

export default LanguageSwitcher;
