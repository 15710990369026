import getAuthenticatedInstance from "./authenticated";
import { BuildingChildInstance } from "./base";

export type TDeviceType =
  | "other"
  | "sensor"
  | "outlet"
  | "light"
  | "blind"
  | "ac_unit"
  | "thermostat"
  | "camera"
  | null;

interface IDevice extends BuildingChildInstance {
  device_class: string;
  alternative_id: string;
  gateway_id: string | null;
  building_id: string;
  room_id: string | null;
  name: string;
  type: TDeviceType;
  disabled: boolean;
  created: string;
  configuration: string;
  update_status?: number;
  update_progress?: number;
}

export const selectableDeviceTypes: TDeviceType[] = [
  "other",
  "sensor",
  "outlet",
  "light",
  "blind",
  "ac_unit",
  "thermostat",
  "camera",
  null,
];

export const saveDevice = async (device: IDevice): Promise<IDevice> => {
  const response = await getAuthenticatedInstance().patch(
    `/device/${device.uuid}/`,
    {
      room_id: device.room_id,
      name: device.name,
      type: device.type,
      disabled: device.disabled,
    },
  );
  return response.data as IDevice;
};

export const deleteDevice = async (uuid: string): Promise<IDevice> => {
  const response = await getAuthenticatedInstance().delete(`/device/${uuid}/`);
  return response.data as IDevice;
};

export const checkDeviceUpdate = async (device: IDevice): Promise<string> => {
  const response = await getAuthenticatedInstance().post(
    `/device/${device.uuid}/update/check/`,
  );
  return response.data;
};

export const startDeviceUpdate = async (device: IDevice): Promise<string> => {
  const response = await getAuthenticatedInstance().post(
    `/device/${device.uuid}/update/start/`,
  );
  return response.data;
};

export const refreshDeviceTraits = async (device: IDevice): Promise<string> => {
  const response = await getAuthenticatedInstance().post(
    `/device/${device.uuid}/refresh/`,
  );
  return response.data;
};

export const discoverDeviceTraits = async (
  device: IDevice,
): Promise<string> => {
  const response = await getAuthenticatedInstance().post(
    `/device/${device.uuid}/discover/`,
  );
  return response.data;
};

export default IDevice;
