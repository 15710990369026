import IRoom from "../services/Room";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";
import roomUIStore from "./UI/RoomUIStore";

export type IRoomFilter = IBuildingChildFilter;

class RoomStore extends BuildingChildPagedStore<IRoom, IRoomFilter> {
  constructor() {
    super("room");
    this.persist();
  }

  sort = (a: IRoom, b: IRoom): 1 | -1 => {
    return roomUIStore.findIndex(a.uuid) < roomUIStore.findIndex(b.uuid)
      ? -1
      : 1;
  };
}

const roomStore = new RoomStore();
export default roomStore;
