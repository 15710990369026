import CachedIcon from "@mui/icons-material/Cached";
import { Box, Button, Paper, Stack, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDraggable } from "react-use-draggable-scroll";
import { IPricePoint } from "../../../services/plugin/Nordpool";
import nordpoolUiStore from "../../../stores/UI/plugin/nordpool/NordpoolUIStore";

export interface IPricePointWithValue extends IPricePoint {
  value: number;
}

export interface IPricePointWithHeat extends IPricePointWithValue {
  heat: string;
}

export interface IUpcomingPrices {
  building: string;
}

export interface IUpcomingPriceElement {
  price: IPricePointWithHeat;
}

export interface IUpcomingPricesStack {
  prices: IPricePointWithValue[];
  updatePrices?: (forceUpdate: boolean) => void;
}

const valueToHeat = (price: number, minPrice: number, maxPrice: number) => {
  // https://stackoverflow.com/a/39776893
  // https://stackoverflow.com/a/27263918
  // https://stackoverflow.com/a/17268489
  const scaledPrice =
    (Math.min(Math.max(price, minPrice), maxPrice) - minPrice) /
    (maxPrice - minPrice);
  const h = (1 - scaledPrice) * 120;
  return `hsl(${h}, 100%, 50%)`;
};

const UpcomingPriceElement: FC<IUpcomingPriceElement> = (
  props: IUpcomingPriceElement,
) => {
  const theme = useTheme();
  const { price } = props;
  const startTime = DateTime.fromISO(price.time);

  return (
    <Stack
      key={price.time}
      direction="column"
      alignItems="center"
      paddingLeft={1}
      paddingRight={1}
      paddingTop={1}
      paddingBottom={1}
      spacing={0.5}
      sx={{
        background: theme.palette.primary.light,
      }}
    >
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        sx={{ userSelect: "none" }}
      >
        <Box
          sx={{
            width: 18,
            height: 18,
            borderRadius: 18 / 2,
            backgroundColor: price.heat,
          }}
        />
        <Typography fontSize={13} lineHeight={1} paddingTop={0.5}>
          {startTime.toFormat("HH")}-
          {startTime.plus({ hours: 1 }).toFormat("HH")}
        </Typography>
      </Stack>
      <Typography fontSize={13} lineHeight={1} paddingTop={0.5}>
        {price.value.toFixed(3)}&nbsp;c/kWh
      </Typography>
    </Stack>
  );
};

export const UpcomingPricesStack: FC<IUpcomingPricesStack> = (
  props: IUpcomingPricesStack,
) => {
  const { t } = useTranslation();
  const { prices, updatePrices } = props;
  const [minPrice, maxPrice] = nordpoolUiStore.energyPriceSettings.usePriceRange
    ? nordpoolUiStore.energyPriceSettings.priceRange || [2, 10]
    : [
        Math.min(...prices.map((x) => x.value)),
        Math.max(...prices.map((x) => x.value)),
      ];
  const draggableRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(draggableRef);

  return (
    <Paper sx={{ marginBottom: 2 }}>
      <Stack
        {...events}
        ref={draggableRef}
        direction="row"
        spacing={1}
        paddingLeft={1}
        paddingRight={1}
        paddingTop={1}
        paddingBottom={1}
        sx={{
          overflowX: "auto",
        }}
      >
        {prices.map((price) => (
          <UpcomingPriceElement
            key={price.time}
            price={{
              ...price,
              heat: valueToHeat(price.value, minPrice, maxPrice),
            }}
          />
        ))}
        {updatePrices !== undefined && (
          <Button
            variant="contained"
            startIcon={<CachedIcon />}
            onClick={() => updatePrices(true)}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              textWrap: "nowrap",
              minWidth: 200,
            }}
          >
            {t("Force update")}
          </Button>
        )}
      </Stack>
    </Paper>
  );
};

const UpcomingPrices: FC<IUpcomingPrices> = observer(
  (props: IUpcomingPrices) => {
    const { building } = props;
    const [prices, setPrices] = useState<IPricePointWithValue[]>([]);
    // const [lastUpdated, setLastUpdated] = useState<DateTime>(DateTime.now());

    const updatePrices = (forceUpdate: boolean = false) => {
      nordpoolUiStore
        .getUpcomingPrices(building, forceUpdate)
        .then((points) => {
          const priceSelection =
            nordpoolUiStore.energyPriceSettings.priceSelection;
          setPrices(
            points.map((point) => {
              let value: number = -100;

              switch (priceSelection) {
                case "spot":
                  value =
                    point.spot === undefined ? 0.0 : parseFloat(point.spot);
                  break;
                case "energy":
                  value = parseFloat(point.energy);
                  break;
                case "total":
                  value = parseFloat(point.total);
                  break;
                default:
                  value = -100;
                  break;
              }

              return {
                ...point,
                value: value,
              };
            }),
          );
          // setLastUpdated(DateTime.now());
        });
    };

    useEffect(() => {
      updatePrices();
      const timer = setInterval(() => {
        updatePrices();
      }, 60000);
      return () => clearInterval(timer);
    }, []);

    return <UpcomingPricesStack prices={prices} updatePrices={updatePrices} />;
  },
);

export default UpcomingPrices;
